/* eslint-disable max-lines */
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from '@utils/i18n/i18n';
import {
  useMemo, useEffect, useState, useCallback, 
} from 'react';
import axios from 'axios';

function useDateGroups(dates, cloudCover) {
  return useMemo(
    () => {
      const defaultValue = { highlightedDays: [], cloudyDays: [], allDays: [] };

      if (!dates) {
        return defaultValue;
      }

      return dates.reduce((acc, curr) => {
        if (curr.minCloudPercentage <= cloudCover) {
          acc.highlightedDays.push(curr.date);
        } else {
          acc.cloudyDays.push(curr.date);
        }

        acc.allDays.push(curr.date);

        return acc;
      }, defaultValue);
    },
    [dates, cloudCover],
  );
}

function useExploreDates(mapBounds, cloudCover, start, end) {
  const { data } = useQuery({
    queryKey: ['explore', 'dates', mapBounds, start, end],
    queryFn: async () => {
      const { data: availableDates } = await axios.get(`/api/catalogue/TERRASCOPE_S2_RHOW_V1/dates?bbox=${mapBounds.join(',')}&start=${start}&end=${end}`);

      return availableDates;
    },
    enabled: !!mapBounds,
    placeholderData: keepPreviousData,
  });

  return useDateGroups(data, cloudCover)
}

function useLocalizedMeasurementTypeOptions() {
  const { t, lang } = useTranslation();

  const getOptions = useCallback(() => [
    {
      label: `${t('chl-a')} (${t('chl-a_unit')})`,
      value: 'TERRASCOPE_S2_CHL_V1',
      translationKey: 'chl-a',
      tooltip: {
        text: t('chl-a_tooltip'),
        img: '/img/tooltips/chl-a.png',
      },
      typeCode: 'CHLA',
      name: t('chl-a'),
      unit: t('chl-a_unit'),

    },
    {
      label: `${t('tsm')} (${t('tsm_unit')})`,
      value: 'TERRASCOPE_S2_SPM_V1',
      translationKey: 'tsm',
      tooltip: {
        text: t('tsm_tooltip'),
      },
      typeCode: 'TSS',
      name: t('tsm'),
      unit: t('tsm_unit'),
    },
    {
      label: `${t('turbidity')} (${t('turbidity_unit')})`,
      value: 'TERRASCOPE_S2_TUR_V1',
      translationKey: 'turbidity',
      tooltip: {
        text: t('turbidity_tooltip'),
      },
      typeCode: 'TUR',
      name: t('turbidity'),
      unit: t('turbidity_unit'),
    },
    {
      label: t('none'),
      value: 'none',
    },
  ], [t]);

  const [options, setOptions] = useState(getOptions);

  useEffect(() => {
    setOptions(getOptions());
  }, [lang, getOptions]);

  return options;
}

function useLocalizedAdditionalOptions() {
  const { t } = useTranslation();

  return [
    { 
      label: t('rhow'),
      value: 'TERRASCOPE_S2_RHOW_V1',
      translationKey: 'rhow',
      tooltip: {
        text: t('rhow_tooltip'),
      },
    },
    {
      label: t('tile_footprints'),
      value: 'TILE_FOOTPRINTS',
      translationKey: 'tile_footprints',
      tooltip: {
        text: t('tile_footprints_tooltip'),
      },
    },
  ];
}

export {
  useExploreDates,
  useDateGroups,
  useLocalizedMeasurementTypeOptions,
  useLocalizedAdditionalOptions,
};