import {
  useRef, useState, useEffect, useMemo, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LegendBox from '@App/components/LegendBox/LegendBox';
import StationMarkers from '@App/components/StationMarkers/StationMarkers';
import Controls from './Controls/Controls';
import DataLayers from './DataLayers/DataLayers';

import { mapFactory, addLayersFromConfigToMap } from './explore-map.factory';
import { useLocalizedMeasurementTypeOptions } from '../explore.hooks';
import { setMapBounds } from '../explore.slice';

import 'leaflet/dist/leaflet.css';
import './explore-map.styl';

function ExploreMap() {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const compare = useSelector(state => state.explore.compare);
  const date = useSelector(state => state.explore.date);
  const measurementType = useSelector(state => state.explore.measurementType);
  const compareDate = useSelector(state => state.explore.compareDate);
  const compareMeasurementType = useSelector(state => state.explore.compareMeasurementType);

  const measurementLayerOptions = useLocalizedMeasurementTypeOptions();

  const [compareHoveredSide, setCompareHoveredSide] = useState();
  const [hoveredMeasurementType, setHoveredMeasurementType] = useState();
  const [hoveredDate, setHoveredDate] = useState();

  useEffect(() => {
    const mapInstance = mapFactory(mapRef.current);

    function updateMapBounds() {
      const bounds = mapInstance.getBounds();
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();

      dispatch(setMapBounds([southWest.lng, southWest.lat, northEast.lng, northEast.lat]));
    }

    updateMapBounds();
    setMap(mapInstance);

    mapInstance.on('moveend', updateMapBounds);
    mapInstance.on('comparesideswitch', ({ cursorIsOnLeft }) => {
      setCompareHoveredSide(cursorIsOnLeft ? 'left' : 'right');
    });

    return () => mapInstance.remove();
  }, [dispatch]);

  useEffect(() => {
    if (!map) return;
    addLayersFromConfigToMap(map);
  }, [map]);

  useEffect(() => {
    if ((!compare || !compareHoveredSide) && measurementType !== 'none') {
      setHoveredMeasurementType(measurementLayerOptions.find(o => o.value === measurementType));
      setHoveredDate(date);
      setCompareHoveredSide(undefined);
    } else if (compareHoveredSide === 'left' && measurementType !== 'none') {
      setHoveredMeasurementType(measurementLayerOptions.find(o => o.value === measurementType));
      setHoveredDate(date);
    } else if (compareMeasurementType !== 'none') {
      setHoveredMeasurementType(measurementLayerOptions
        .find(o => o.value === compareMeasurementType));
      setHoveredDate(compareDate);
    }
  }, [
    measurementType, compareMeasurementType, compare, compareHoveredSide, 
    measurementLayerOptions, date, compareDate,
  ]);

  const shouldDisplayLegend = useMemo(() => map && (
    (!compare && measurementType !== 'none') 
    || (compare && (measurementType !== 'none' || compareMeasurementType !== 'none'))
  ), [map, compare, compareMeasurementType, measurementType]);

  useEffect(() => {
    if (!map) return;
    if (shouldDisplayLegend) {
      map.fire('no-measurement-layer', { moveUp: true });
    } else map.fire('no-measurement-layer', { moveUp: false });
  }, [map, shouldDisplayLegend]);

  return (
    <div ref={mapRef} className="leaflet-map explore-map">
      <Controls map={map} />
      <DataLayers map={map} />
      {(shouldDisplayLegend) && (
        <LegendBox
          map={map}
          measurementType={hoveredMeasurementType}
          date={hoveredDate}
        />
      )}
      <StationMarkers map={map} />
    </div>
  );
}

export default ExploreMap;