import { layers } from '@App/config/raster.layers';

const controls = [
  {
    identifier: 'search',
    position: 'topright',
  },
  {
    identifier: 'zoom',
    position: 'topright',
  },
  {
    identifier: 'scale',
    position: 'bottomright',
  },
  {
    identifier: 'baselayers',
    position: 'bottomleft',
    baselayers: [
      {
        id: 'maptiler-base-layer',
        title: 'controls_layers_street',
        composition: [
          'maptiler-base-layer',
        ],
        labels: 'maptiler-street-labels',
        img: '/img/leaflet/street.png',
        isActive: true,
        tooltip: 'baselayer_default_tooltip',
      },
      {
        id: 'esri-satellite-layer',
        title: 'controls_layers_satellite',
        composition: [
          'esri-satellite-layer',
        ],
        labels: 'maptiler-satellite-labels',
        img: '/img/leaflet/satellite.png', 
        tooltip: 'baselayer_satellite_tooltip',
        esri: true,
      },
    ],
  },
]

export {
  layers,
  controls,
}