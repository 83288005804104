import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cloudCoverage: 90,
  date: undefined,
  measurementType: 'TERRASCOPE_S2_CHL_V1',
  mapBounds: undefined,
  compare: false,
  compareDate: undefined,
  compareMeasurementType: 'TERRASCOPE_S2_CHL_V1',
  additionalLayer: undefined,
}

const appWindow = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setCloudCoverage: (state, action) => {
      state.cloudCoverage = action.payload
    },
    setDate: (state, action) => {
      state.date = action.payload
    },
    setMeasurementType: (state, action) => {
      state.measurementType = action.payload
    },
    setCompare: (state, action) => {
      state.compare = action.payload
    },
    setCompareDate: (state, action) => {
      state.compareDate = action.payload
    },
    setCompareMeasurementType: (state, action) => {
      state.compareMeasurementType = action.payload
    },
    setAdditionalLayer: (state, action) => {
      state.additionalLayer = action.payload
    },
    setMapBounds: (state, action) => {
      state.mapBounds = action.payload;
    },
  },
})

export const {
  setCloudCoverage,
  setDate,
  setMeasurementType,
  setCompare,
  setCompareDate,
  setCompareMeasurementType,
  setAdditionalLayer,
  setMapBounds,
} = appWindow.actions

export default appWindow.reducer