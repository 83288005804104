import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import Sidepanel from '@App/components/Sidepanel/Sidepanel';

import { useTranslation } from '@utils/i18n/i18n';
import { useMeasurements } from '@services/query.service';

import TimeSeriesForm from './TimeSeriesForm/TimeSeriesForm';
import TimeSeriesMap from './TimeSeriesMap/TimeSeriesMap';
import TimeSeriesGraph from './TimeSeriesGraph/TimeSeriesGraph';

import { setActiveDate, setActiveMeasurement } from './time-series.slice';

import './time-series.styl';

function TimeSeries() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const station = useSelector(state => state.timeSeries.station);
  const activeDate = useSelector(state => state.timeSeries.activeDate);

  const { data: measurements } = useMeasurements(station?.id);

  const uniqueDates = useMemo(() => ([...new Set(measurements?.map(m => m.date))]), [measurements]);

  useEffect(() => {
    if (uniqueDates.length && (!activeDate || !uniqueDates.includes(activeDate))) {
      dispatch(setActiveDate(uniqueDates[uniqueDates.length - 1]));
    }
  }, [dispatch, activeDate, uniqueDates]);

  return (
    <div className="time-series">
      <Sidepanel 
        className="time-series__sidepanel"
        title={t('time_series')}
        icon="img/pages/time-series.svg"
        intro={t('time_series_intro_text')}
      >
        <TimeSeriesForm />
      </Sidepanel>
      <div className="time-series__content">
        <TimeSeriesMap />
        {measurements?.length > 0 && station && activeDate ? (
          <TimeSeriesGraph
            setActiveMeasurement={v => dispatch(setActiveMeasurement(v))}
            setActiveDate={v => dispatch(setActiveDate(format(v, 'yyyy-MM-dd')))}
            uniqueDates={uniqueDates}
            measurements={measurements}
          />
        ) : (
          <div className="time-series-graph">
            <div className="time-series-graph__no-waterbody">
              { station ? t('time_series_no_available') : t('time_series_please_select') }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeSeries;
