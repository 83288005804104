const DEFAULT_MAX = 999;

function getMaxValue(measurements, measurementType, statisticalValueType) {
  if (!measurements?.length || !measurementType || !statisticalValueType) {
    return DEFAULT_MAX;
  }

  const measurementsForType = measurements.filter(({ indicator }) => indicator === measurementType);

  if (!measurementsForType.length) {
    return DEFAULT_MAX;
  }

  return Math.ceil(Math.max(...measurementsForType.map(m => m[statisticalValueType]).filter(Boolean)));
}

export {
  getMaxValue,
};