import React from 'react';
import * as plotty from '@utils/Plotty/plotty';
import './legend.styl';

class Legend extends React.Component {
  constructor(props) {
    super(props);
    this.renderColorScaleToCanvas = this.renderColorScaleToCanvas.bind(this);
  }

  componentDidMount() {
    this.renderColorScaleToCanvas(
      this.props.colorScale,
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.colorScale !== prevProps.colorScale) {
      this.renderColorScaleToCanvas(
        this.props.colorScale,
      );
    }
  }

  renderColorScaleToCanvas(name) {
    const colorScaleName = name ?? this.props.colorScale ?? 'viridis';
    const canvas = document.getElementById(`legend-scale-${this.props.layerId}`);
    if (canvas) {
      const csDef = plotty.colorscales[colorScaleName];
      const colors = [...csDef.colors];
      let positions = [...csDef.positions];

      // only use half of the color scale range for the legend
      const removedValues = [...csDef.positions].map(p => p * 2).filter(p => p > 1).length;
      positions = positions.map(p => p * 2).filter(p => p <= 1);
      colors.splice(-removedValues);
      
      const ctx = canvas.getContext('2d');

      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);

      for (let i = 0; i < colors.length; i += 1) {
        gradient.addColorStop(positions[i], colors[i]);
      }
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  }

  render() {   
    let { min, max } = this.props.scale ?? this.props.layerOptions.scale;
    const height = 16;
    const width = 280;
    const newMin = 0;
    const newMax = width + newMin;
    let value = this.props.hoveredValue ? this.props.hoveredValue : 0.0;
    if (this.props.layerOptions && this.props.layerOptions.conversionFunction) {
      value = this.props.layerOptions.conversionFunction(value);
      min = this.props.layerOptions.conversionFunction(min);
      max = this.props.layerOptions.conversionFunction(max);
    }

    let pos = ((value - min) * (newMax - newMin)) / (max - min) + newMin;
    if (pos > newMax) pos = newMax;
    if (pos < newMin) pos = newMin;

    let displayVal = value;
    let displayMin = min;
    let displayMax = max;

    if (this.props.layerOptions && this.props.layerOptions.scalingFunction) {
      const fx = this.props.layerOptions.scalingFunction;
      displayVal = fx(value);
      displayMin = fx(min);
      displayMax = fx(max);
    }

    const numberOfDecimals = this.props.layerOptions?.decimals ?? 2;

    return (
      <div
        className={`legend ${this.props.className ? this.props.className : ''} js-legend`}
      >

        <div className="legend__content">
          <canvas
            id={`legend-scale-${this.props.layerId}`}
            className="legend__list"
            height={height}
            width={width}
          />

          <div className="legend__max">{Number(displayMax.toFixed(2))}</div>
          <div className="legend__fourth">{Number((((displayMin + displayMax) / 4) * 3).toFixed(2))}</div>
          <div className="legend__half">{Number(((displayMin + displayMax) / 2).toFixed(2))}</div>
          <div className="legend__second">{Number(((displayMin + displayMax) / 4).toFixed(2))}</div>
          <div className="legend__min">{Number(displayMin.toFixed(2))}</div>
        </div>
        {(this.props.showPointer && (this.props.hoveredValue !== undefined)) && (
          <div className="legend__pointer" style={{ left: `${pos}px` }}>
            <div className="legend__pointer__content">
              <p>{Number(displayVal.toFixed(numberOfDecimals))}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Legend;
