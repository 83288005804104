/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useTranslation } from '@utils/i18n/i18n';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClickAwayListener } from '@mui/material';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import IconButton from '@library/IconButton/IconButton';
import { faCalendar, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/vi';

import './tile-date-picker.styl';

function TileDatePicker(props) {
  const { t } = useTranslation();

  const {
    value,
    onChange,
    highlightedDates,
    greyedOutDates,
    allDates,
    cloudsPercentage,
    externalOpenToggle,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [nextDate, setNextDate] = useState();
  const [prevDate, setPrevDate] = useState();

  useEffect(() => {
    if (externalOpenToggle || externalOpenToggle === false) {
      setIsOpen(externalOpenToggle);
    }
  }, [externalOpenToggle]);

  useEffect(() => {
    setPrevDate(allDates[
      allDates.findIndex(e => e === new Date(value).toDateString()) + 1
    ]);
    setNextDate(allDates[
      allDates.findIndex(e => e === new Date(value).toDateString()) - 1
    ]);
  }, [value, allDates]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <ClickAwayListener onClickAway={() => !externalOpenToggle && setIsOpen(false)}>
        <div style={{ width: '100%' }}>
          <DatePicker
            {...props}
            value={value}
            onChange={newValue => onChange(newValue.$d)}
            open={isOpen}
            onAccept={() => setIsOpen(false)}
            inputFormat={'d MMMM yyyy'.toUpperCase()}
            disableMaskedInput
            disableHighlightToday
            renderInput={({
              inputRef, inputProps,
            }) => {
              return (
                <div className="tile-date-picker">
                  <img 
                    onClick={props.onPreviewClick} 
                    className="tile-date-picker__image" 
                    src={props.previewUrl} 
                    title={cloudsPercentage ? `${Number(cloudsPercentage).toFixed(0)}% ${'cloud cover'}` : t('preview')} 
                  />
                  <input className="tile-date-picker__input" ref={inputRef} {...inputProps} />
                  <div className="tile-date-picker__controls">
                    <IconButton
                      title={t('prev_date')}
                      className="tile-date-picker__controls__button tile-date-picker__controls__button--prev"
                      icon={faChevronLeft}
                      iconColor="#000"
                      onClick={() => onChange(new Date(prevDate))}
                      disabled={!prevDate}
                    />
                    <IconButton
                      title={t('open_date_selector')}
                      className="tile-date-picker__controls__button"
                      icon={faCalendar}
                      iconColor="#000"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                    <IconButton
                      title={t('next_date')}
                      className="tile-date-picker__controls__button tile-date-picker__controls__button--next"
                      icon={faChevronRight}
                      iconColor="#000"
                      onClick={() => onChange(new Date(nextDate))}
                      disabled={!nextDate}
                    />
                    {props.onRemovedDate && (
                      <IconButton
                        title={t('remove')}
                        icon={faTimes}
                        className="tile-date-picker__controls__button tile-date-picker__controls__button--remove"
                        onClick={props.onRemovedDate}
                      />
                    )}
                  </div>
                </div>
              )
            }}
            renderDay={(day, selected, DayProps) => {
              const { key, ...dProps } = DayProps;
              const dateString = new Date(day).toDateString();

              if (highlightedDates && highlightedDates.includes(dateString)) {
                return (
                  <div title={t('download_date_blue_tooltip')} key={key}>
                    <PickersDay
                      {...dProps}
                      className="tile-date-picker__selector__day tile-date-picker__selector__day--highlighted"
                    />
                  </div>
                );
              }

              if (greyedOutDates && greyedOutDates.includes(dateString)) {
                return (
                  <div title={t('download_date_grey_tooltip')} key={key}>
                    <PickersDay
                      {...dProps}
                      className="tile-date-picker__selector__day tile-date-picker__selector__day--greyed"
                    />
                  </div>
                );
              }

              return <PickersDay {...dProps} className="tile-date-picker__selector__day" key={key} />
            }}
          />
        </div>
      </ClickAwayListener>
    </LocalizationProvider>
  )
}

export default TileDatePicker;