import React from 'react';

import { fetchControls } from './controls.utils';

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.state = { controls: null }
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.map && !prevProps.map) {
      fetchControls(this.props.map).then((controls) => {
        if (this._isMounted) {
          this.setState({ controls });
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return this.state.controls;
  }
}

export default Controls;
