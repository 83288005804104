function getUniqueIndicators(measurements) {
  if (!measurements?.length) {
    return [];
  }

  const indicators = new Set();

  measurements.forEach(({ indicator }) => {
    indicators.add(indicator);
  });

  return [...indicators];
}

export {
  getUniqueIndicators,
};