import { useState, useRef, useCallback } from 'react';
import { useTranslation } from '@utils/i18n/i18n';
import { NavLink } from 'react-router-dom';
import routes from '@App/app.routes';
import { Button, Icon } from '@nazka/nazka.mapframe.components';

import { useOnClickOutside } from '@services/hooks.service';

import './navbar.styl';

function Navbar() {
  const {
    t, setLang, lang, languages, 
  } = useTranslation();
  const [showLanguagePicker, setShowLanguagePicker] = useState(false);
  const languageRef = useRef();

  useOnClickOutside(languageRef, useCallback(() => {
    setShowLanguagePicker(false);
  }, []));

  return (
    <div className="navbar">
      <NavLink className="navbar__brand" to="/">
        <img className="navbar__brand__image" src="/img/logo/explore-logo.svg" alt="Explore VN logo" />
      </NavLink>
      <div className="navbar__menu">
        <div className="navbar__menu__section navbar__menu__section--top">
          {routes.filter(r => r.navPosition === 'top').map((route) => {
            return (
              <li
                key={route.name}
                className={
                  `navbar__menu__section__item${route.disabled ? ' disabled' : ''}`
                  + ` navbar__menu__section__item--${route.name.toLowerCase().replace(/\s+/g, '-')}`
                }
              >
                <NavLink
                  to={route.path}
                  className={({ isActive }) => (
                    `navbar__menu__section__item__link${isActive ? ' navbar__menu__section__item__link--active' : ''}`
                  )}
                >
                  <Icon
                    className="navbar__menu__section__item__link__icon"
                    icon={route.icon}
                  />
                  <span
                    className="navbar__menu__section__item__link__text"
                  >
                    {t(route.key)}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </div>
        <div className="navbar__menu__section navbar__menu__section--bottom">
          <li
            className="navbar__menu__section__item navbar__menu__section__item--language-control"
            ref={languageRef}
          >
            <div className="navbar__menu__section__item__language">
              <Button 
                className="navbar__menu__section__item__language__button"
                onClick={() => setShowLanguagePicker(!showLanguagePicker)}
              >
                {lang}
              </Button>
              <div className={`navbar__menu__section__item__language__menu${showLanguagePicker ? '' : ' hide'}`}>
                {languages.map(l => (
                  <Button 
                    className="navbar__menu__section__item__language__menu__button"
                    onClick={() => {
                      setLang(l);
                      setShowLanguagePicker(!showLanguagePicker);
                    }}
                    key={l}
                  >
                    {l}
                  </Button>
                ))}
              </div>
            </div>
          </li>
          {routes.filter(r => r.navPosition === 'bottom').map((route) => {
            return (
              <li
                key={route.name}
                className={`navbar__menu__section__item${route.disabled ? ' disabled' : ''}`}
              >
                <NavLink
                  to={route.path}
                  className="navbar__menu__section__item__link"
                >
                  <Icon
                    className="navbar__menu__section__item__link__icon"
                    icon={route.icon}
                  />
                  <span
                    className="navbar__menu__section__item__link__text"
                  >
                    {route.name}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
