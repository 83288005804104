import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  station: undefined,
  measurementSelection: [],
  activeMeasurement: undefined,
  activeDate: undefined,
  statisticalValueType: { label: 'Mean', id: 'mean' },
  minValidPixelsPercentage: 0,
  additionalLayer: undefined,
  mapBounds: undefined,
};

const timeSeries = createSlice({
  name: 'timeSeries',
  initialState,
  reducers: {
    setStation: (state, action) => {
      state.station = action.payload;
    },
    setMeasurementSelection: (state, action) => {
      state.measurementSelection = action.payload;
    },
    setActiveMeasurement: (state, action) => {
      state.activeMeasurement = action.payload;
    },
    setActiveDate: (state, action) => {
      state.activeDate = action.payload;
    },
    setStatisticalValueType: (state, action) => {
      state.statisticalValueType = action.payload;
    },
    setMinValidPixelsPercentage: (state, action) => {
      state.minValidPixelsPercentage = action.payload;
    },
    setAdditionalLayer: (state, action) => {
      state.additionalLayer = action.payload;
    },
    setMapBounds: (state, action) => {
      state.mapBounds = action.payload;
    },
  },
});

export const {
  setStation,
  setMeasurementSelection,
  setActiveMeasurement,
  setActiveDate,
  setStatisticalValueType,
  setMinValidPixelsPercentage,
  setAdditionalLayer,
  setMapBounds,
} = timeSeries.actions;

export default timeSeries.reducer;