/* eslint-disable max-lines */
import { useMemo } from 'react';
import { useTranslation } from '@utils/i18n/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { useProducts } from '@utils/QueryClient/products.queries';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { faCrosshairs, faTimes } from '@fortawesome/pro-light-svg-icons';

import TileDatePicker from '@App/components/TileDatePicker/TileDatePicker';
import Tooltip from '@App/components/Tooltip/Tooltip';
import { Icon } from '@nazka/nazka.mapframe.components';

import { useDateGroups } from '@services/hooks.service';
import { shouldDisableFormDate } from '@services/utils.service';
import { focusMapOnFeature, removeTile, updateSelectedTiles } from '@App/pages/Download/download.slice';
import {
  filterSelectableDates, getPreviewUrl, 
} from './selected-tile.utils';

import './selected-tile.styl';

function SelectedTile({ tile, showPreview }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tileid } = tile.properties;
  const dates = useMemo(() => tile.properties.dates.map(d => new Date(d)), [tile]);
  const cloudCoverage = useSelector(state => state.download.cloudCoverage);
  const selectionType = useSelector(state => state.download.selectionType);

  const {
    data,
  } = useProducts({
    start: '2022-11-01',
    end: '2023-12-31',
    tileId: tileid,
  });

  const { 
    availableDates,
    highlightedDates,
    cloudyDates,
  } = useDateGroups(data?.features, cloudCoverage);

  function onDatesChange(newDates) {
    dispatch(updateSelectedTiles(
      [{ 
        ...tile, 
        properties: { 
          ...tile.properties, 
          dates: newDates.map((d) => {
            const utcDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            return utcDate.toISOString()
          }),
        },
      }],
    ));
  }

  return (
    <div className="form-tile">
      <div className="form-tile__header">
        <p className="form-tile__header__name">{tileid}</p>
        <div className="form-tile__header__buttons">
          <Tooltip
            place="right"
            id={`${tileid}_focus_tooltip`}
            tooltip={(<p>{t('download_zoom_to_tile')}</p>)}
          >
            <Icon
              className="form-tile__header__buttons__button"
              icon={faCrosshairs}
              rootElProps={{ 
                onClick: () => dispatch(focusMapOnFeature(tile)),
              }}
            />
          </Tooltip>
          {selectionType === 'click-selection' && (
            <Tooltip
              place="right"
              id={`${tileid}_focus_tooltip`}
              tooltip={(<p>{t('download_remove_tile')}</p>)}
            >
              <Icon
                className="form-tile__header__buttons__button form-tile__header__buttons__button--remove"
                icon={faTimes}
                rootElProps={{ 
                  onClick: () => dispatch(removeTile(tile)),
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      {!!dates.length && dates.map((date, i) => {
        const previewUrl = getPreviewUrl(date, tileid);
        const tileData = data?.features.find(f => f.id.includes(tileid));
        const cloudCover = tileData?.properties?.productInformation?.cloudCover;
        const allDates = availableDates.sort((a, b) => b.getTime() - a.getTime());

        return (
          <div className="form-tile__date-wrapper" key={date.getTime()}>
            <TileDatePicker
              value={date}
              previewUrl={previewUrl}
              highlightedDates={filterSelectableDates(highlightedDates, dates, date)}
              greyedOutDates={filterSelectableDates(cloudyDates, dates, date)}
              allDates={allDates.map(d => d.toDateString())}
              cloudsPercentage={cloudCoverage}
              minDate={new Date('2022-11-01')}
              maxDate={new Date('2023-12-31')}
              onPreviewClick={() => showPreview({
                url: previewUrl, date, tileid, cloudCover, 
              })}
              onChange={(newDate) => {
                dates.splice(i, 1, newDate);
                onDatesChange(dates);
              }}
              shouldDisableDate={d => (
                shouldDisableFormDate(d.$d, dates, availableDates)
              )}
              onRemovedDate={() => {
                dates.splice(i, 1);
                onDatesChange(dates);
              }}
            />
          </div>
        );
      })}
      {!dates.length && !!availableDates.length && (
        <div className="form-tile__empty">
          {t('download_no_date_selected')}
        </div>
      )}
      {!availableDates.length && (
        <div className="form-tile__empty">
          {t('download_no_dates_available')}
        </div>
      )}
      {!!availableDates.length && (
        <button
          className="form-tile_add"
          type="button"
          onClick={() => {
            onDatesChange([...dates, availableDates.sort((a, b) => a - b).find(d1 => (
              !dates.findLast(d2 => d2.toDateString() === d1.toDateString())
            ))]);
          }}
        >
          <Icon icon={faAdd} className="form-tile_add__icon" />
          <span>{t('download_add_date')}</span>
        </button>
      )}
    </div>
  );
}

export default SelectedTile;