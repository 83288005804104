import { useTranslation } from '@utils/i18n/i18n';

function useLocalizedDownloadOptions() {
  const { t } = useTranslation();

  return [
    {
      label: t('rhow'),
      value: 'TERRASCOPE_S2_RHOW_V1',
      translationKey: 'rhow',
      tooltip: {
        text: t('rhow_tooltip'),
      },
    },
    {
      label: t('chl-a'),
      value: 'TERRASCOPE_S2_CHL_V1',
      translationKey: 'chl-a',
      tooltip: {
        text: t('chl-a_tooltip'),
        img: '/img/tooltips/chl-a.png',
      },
    },
    {
      label: t('tsm'),
      value: 'TERRASCOPE_S2_SPM_V1',
      translationKey: 'tsm',
      tooltip: {
        text: t('tsm_tooltip'),
      },
    },
    {
      label: t('turbidity'),
      value: 'TERRASCOPE_S2_TUR_V1',
      translationKey: 'turbidity',
      tooltip: {
        text: t('turbidity_tooltip'),
      },
    },
  ];
}

export {
  useLocalizedDownloadOptions,
};
