function downloadBlob(blob, filename) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', (filename || ''));
  
  document.body.appendChild(link);
  
  link.click();
  
  window.URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
}

export {
  downloadBlob,
}