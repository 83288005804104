import { useCallback, cloneElement } from 'react';

import Button from '@library/Button/Button';
import { downloadBlob } from '@library/FileDownload/file-download.utils';
import { fetchFile } from '@services/data.service';

function FileDownload(props) {
  const {
    href, blob, label, filename, onDownloadProgress, children, 
  } = props;

  const downloadFile = useCallback((e) => {
    e.preventDefault();

    if (blob) {
      return downloadBlob(blob, filename);
    }

    return fetchFile(href, onDownloadProgress)
      .then(response => downloadBlob(new Blob([response.data]), filename));
  }, [href, blob, filename, onDownloadProgress])

  if (!children) {
    return (
      (onDownloadProgress || blob) 
        ? <Button onClick={downloadFile}>{label || 'download'}</Button> 
        : <a href={href} download={filename || true}>{label || 'download'}</a>
    )
  }

  if (children.type === 'a') {
    return (
      (onDownloadProgress || blob) 
        ? cloneElement(children, { href: '#', role: 'button', onClick: downloadFile })
        : cloneElement(children, { href, download: (filename || true) })
    )
  }

  if (children.length) {
    return (
      (onDownloadProgress || blob) 
        ? <a href="#" role="button" onClick={downloadFile}>{children}</a>
        : <a href={href} download={filename || true}>{children}</a>
    )
  }

  return (
    (onDownloadProgress || blob) 
      ? cloneElement(children, { onClick: downloadFile })
      : <a href={href} download={filename || true}>{children}</a>
  )
}

export default FileDownload;