import { useEffect, useRef } from 'react';

function TrueColorImageLayer({ map, date, layerId }) {
  const layer = useRef();

  useEffect(() => {
    if (map && date) {
      const wmtsUrl = (
        'https://services.terrascope.be/wmts/v2?Service=WMTS&Request=GetTile&Format=image/png'
        + '&Version=1.0.0&tilematrixset=EPSG:3857&TileMatrix=EPSG:3857:{z}&TileCol={x}&TileRow={y}'
        + `&layer=${layerId}&TIME=${date}&style=`
      );
      
      layer.current = L.tileLayer(wmtsUrl, {
        pane: 'additional-layers',
      }).addTo(map);
    }

    return () => {
      layer?.current?.remove();
    };
  }, [map, date, layerId])

  return null;
}

export default TrueColorImageLayer;