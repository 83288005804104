import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

function useDownloadItems(tiles, collections) {
  return useQuery({
    queryKey: ['download-items', tiles, collections],
    queryFn: async () => {
      const { data } = await axios.post('/api/download/items', {
        tiles,
        collections,
      });
      return data;
    },
  });
}

function useWebLinks() {
  return useQuery({
    queryKey: ['web-links'],
    queryFn: async () => {
      const { data } = await axios.get('/api/download/web-links');
      return data;
    },
  });
}

function useDocuments() {
  return useQuery({
    queryKey: ['documents'],
    queryFn: async () => {
      const { data } = await axios.get('/api/download/documents');
      return data;
    },
  });
}

export {
  useDocuments,
  useDownloadItems,
  useWebLinks,
};