function checkForAndMoveOverlapingLabels(className) {
  let tooltips = [...document.querySelectorAll(className)];
  tooltips = tooltips.sort((a, b) => {
    const textA = a.querySelector(`${className}__text`).textContent;
    const textB = b.querySelector(`${className}__text`).textContent;
    const numberA = Number(textA.substring(0, textA.indexOf(' ')));
    const numberB = Number(textB.substring(0, textB.indexOf(' ')));
    return numberB - numberA;
  });

  for (let i = 0; i < tooltips.length; i += 1) {
    tooltips[i].closest('.recharts-label-list').style.transform = '';
  }

  for (let i = tooltips.length - 1; i > 0; i -= 1) {
    const rectA = tooltips[i].getBoundingClientRect();
    const rectB = tooltips[i - 1].getBoundingClientRect();
    const overlap = rectA.top - rectB.bottom;
    if (-overlap > 0) {
      tooltips[i - 1].closest('.recharts-label-list').style.transform = `translateY(${overlap - 1.5}px)`;
      tooltips[i - 1].setAttribute('translateY', overlap - 1.5);
    }
  }

  // for (let i = 0; i < tooltips.length - 1; i += 1) {
  //   const rectA = tooltips[i].getBoundingClientRect();
  //   const rectB = tooltips[i + 1].getBoundingClientRect();
  // }
}

function getDaysArray(start, end, interval) {
  const arr = []
  const dt = new Date(start);
  for (arr, dt; dt <= new Date(end); dt.setDate(dt.getDate() + interval)) {
    arr.push(new Date(dt));
  }
  return arr;
}

function nearestDate(dates, target) {
  let nearest = Infinity
  let winner = -1

  dates.forEach((date, index) => {
    const distance = Math.abs(date.getTime() - target.getTime())
    if (distance < nearest) {
      nearest = distance
      winner = index
    }
  })

  return winner;
}

export { checkForAndMoveOverlapingLabels, getDaysArray, nearestDate };