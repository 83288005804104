/* eslint-disable max-lines */
import { useEffect } from 'react';
import { useTranslation } from '@utils/i18n/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Button } from '@nazka/nazka.mapframe.components';
import { faInfoCircle, faPlus } from '@fortawesome/pro-regular-svg-icons';

import Tooltip from '@App/components/Tooltip/Tooltip';
import ImageTooltip from '@App/components/Tooltip/ImageTooltip/ImageTooltip';
import CloudCoverageSlider from '@App/components/CloudCoverageSlider/CloudCoverageSlider';
import DatePicker from '@App/components/DatePicker/DatePicker';
import Radiogroup from '@App/components/Radiogroup/Radiogroup';
import ExtraLayers from '@App/components/ExtraLayers/ExtraLayers';

import { useExploreDates, useLocalizedMeasurementTypeOptions } from '../explore.hooks';

import { 
  setCloudCoverage, setDate, setMeasurementType, setCompare, setCompareDate, 
  setCompareMeasurementType, setAdditionalLayer,
} from '../explore.slice';

import './explore-form.styl';

function ExploreForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const measurementLayerOptions = useLocalizedMeasurementTypeOptions();

  const cloudCoverage = useSelector(state => state.explore.cloudCoverage);
  const date = useSelector(state => state.explore.date);
  const measurementType = useSelector(state => state.explore.measurementType);
  const compare = useSelector(state => state.explore.compare);
  const compareDate = useSelector(state => state.explore.compareDate);
  const compareMeasurementType = useSelector(state => state.explore.compareMeasurementType);
  const additionalLayer = useSelector(state => state.explore.additionalLayer);
  const mapBounds = useSelector(state => state.explore.mapBounds);

  const {
    highlightedDays,
    cloudyDays,
    allDays,
  } = useExploreDates(mapBounds, cloudCoverage, '2022-11-01', '2023-12-31');

  useEffect(() => {
    if (!highlightedDays.length) return;
    
    const firstDay = highlightedDays[highlightedDays.length - 1];

    if (!compareDate) {
      dispatch(setCompareDate(new Date(`${firstDay} UTC`).toISOString()));
    }

    if (!date) {
      dispatch(setDate(new Date(`${firstDay} UTC`).toISOString()));
    }
  }, [highlightedDays, dispatch, compareDate, date]);

  return (
    <div className="explore-form">
      <div className="explore-form__sensor">
        <p>{t('explore_sensor_text')}</p>
        <Tooltip
          place="right"
          id="download_sensor_tooltip"
          style={{ opacity: 1 }}
          tooltip={(
            <ImageTooltip 
              text={t('explore_sensor_tooltip')}
              img="/img/tooltips/Sentinel-2.jpg"
            />
        )}
        >
          <Icon 
            className="explore-form__sensor__icon" 
            icon={faInfoCircle}
            rootElProps={{ 
              'data-tooltip-id': 'download_sensor_tooltip',
            }}
          />
        </Tooltip>
      </div>
      <CloudCoverageSlider
        min={0}
        max={100}
        value={cloudCoverage}
        setValue={v => dispatch(setCloudCoverage(v))}
      />
      <DatePicker
        value={date}
        onChange={(newValue) => {
          dispatch(setDate(newValue));
          if (!compare) dispatch(setCompareDate(newValue)); 
        }}
        shouldDisableDate={d => !allDays.includes(new Date(d).toDateString())}
        highlightedDays={highlightedDays}
        greyedOutDays={cloudyDays}
        allDays={allDays}
        placeholder={t('no_date_selected')}
        minDate={new Date('2022-11-01')}
        maxDate={new Date('2023-12-31')}
      />
      <label id="explore-form-water-quality">
        <span className="explore-form__label">
          {t('explore_measurement_types')}
        </span>
        <Radiogroup
          name="measurement-type"
          className="explore-form__label__radio-group"
          radios={measurementLayerOptions}
          selectedValue={measurementType}
          onChange={o => dispatch(setMeasurementType(o))}
          showTooltips
        />
      </label>
      <div className={`explore-form__compare${compare ? ' explore-form__compare--active' : ''}`}>
        <Button 
          onClick={() => dispatch(setCompare(!compare))}
          className="explore-form__compare__toggle"
          icon={faPlus}
          hover={false}
        >
          {t('explore_compare')}
        </Button>
        <DatePicker
          value={compareDate}
          onChange={newValue => dispatch(setCompareDate(newValue))}
          highlightedDays={highlightedDays}
          shouldDisableDate={d => !allDays.includes(new Date(d).toDateString())}
          greyedOutDays={cloudyDays}
          allDays={allDays}
          placeholder={t('no_date_selected')}
        />
        <label>
          <span className="explore-form__label">
            {t('explore_measurement_types')}
          </span>
          <Radiogroup
            name="compare-measurement-type"
            className="compare-measurement-type__radio-group"
            radios={measurementLayerOptions}
            selectedValue={compareMeasurementType}
            onChange={o => dispatch(setCompareMeasurementType(o))}
          />
        </label>
      </div>
      <ExtraLayers 
        additionalLayer={additionalLayer}
        setAdditionalLayer={v => dispatch(setAdditionalLayer(v))}
      />
    </div>
  )
}

export default ExploreForm;