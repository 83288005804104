import { useTranslation } from '@utils/i18n/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { Icon } from '@nazka/nazka.mapframe.components';
import Checkbox from '@App/components/Checkbox/Checkbox';
import CloudCoverageSlider from '@App/components/CloudCoverageSlider/CloudCoverageSlider';
import SelectedTiles from '@App/pages/Download/DownloadForm/SelectedTiles/SelectedTiles';
import Footer from '@App/pages/Download/DownloadForm/Footer/Footer';

import { faHandPointer, faDrawSquare } from '@fortawesome/pro-light-svg-icons';

import { useLocalizedDownloadOptions } from '../download.hooks';

import { 
  setMeasurementTypes, setCloudCoverage, setSelectionType,
} from '../download.slice';

import './download-form.styl';

function DownloadForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const downloadOptions = useLocalizedDownloadOptions();

  const measurementTypes = useSelector(state => state.download.measurementTypes);
  const cloudCoverage = useSelector(state => state.download.cloudCoverage);
  const selectionType = useSelector(state => state.download.selectionType);

  const selectedTiles = useSelector(state => state.download.selectedTiles);

  const [clip, setClip] = useState('clip', 'mergeAndClip', false);
  const [mergeAndClip, setMergeAndClip] = useState(false);

  return (
    <div className="download-form">
      <div className="download-form__measurements">
        <span className="download-form__label">
          {t('download_data_types')}
        </span>
        {downloadOptions.map((option) => {
          return (
            <Checkbox
              key={option.value}
              className="download-form__checkbox"
              label={option.label}
              tooltip={option.tooltip}
              checked={measurementTypes.find(m => m.value === option.value)}
              onChange={(e, isChecked) => {
                const nextMeasurementSelection = isChecked ? [...measurementTypes, option] 
                  : measurementTypes.filter(ms => ms.value !== option.value);
                dispatch(setMeasurementTypes(nextMeasurementSelection));
              }}
            />
          )
        })}
      </div>
      <CloudCoverageSlider
        min={0}
        max={100}
        value={cloudCoverage}
        setValue={v => dispatch(setCloudCoverage(v))}
      />
      <div className="download-form__toggle">
        <span className="download-form__label">
          {t('download_tile_selection')}
        </span>
        <button
          className={`download-form__toggle__side${selectionType === 'click-selection' ? ' active' : ''}`} 
          onClick={() => dispatch(setSelectionType('click-selection'))}
          type="button"
        >
          <Icon icon={faHandPointer} size={{ height: '19px', width: '100%' }} />
        </button>
        <button 
          className={`download-form__toggle__side${selectionType === 'draw-selection' ? ' active' : ''}`} 
          onClick={() => dispatch(setSelectionType('draw-selection'))}
          type="button"
        >
          <Icon icon={faDrawSquare} size={{ height: '19px', width: '100%' }} />
        </button>
      </div>
      <SelectedTiles
        tiles={selectedTiles[selectionType]}
      />
      <Footer 
        selectedTiles={selectedTiles[selectionType]}
        measurementSelection={measurementTypes}
        selectionType={selectionType}
        clip={clip}
        setClip={setClip}
        mergeAndClip={mergeAndClip}
        setMergeAndClip={setMergeAndClip}
      />
    </div>
  )
}

export default DownloadForm;