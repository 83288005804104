import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

function useStations() {
  return useQuery({
    queryKey: ['stations'],
    queryFn: async () => {
      const { data } = await axios.get('/api/stations');

      return data;
    },
  });
}

function useMeasurements(stationId) {
  return useQuery({
    queryKey: ['measurements', stationId],
    queryFn: async () => {
      const { data } = await axios.get(`/api/time-series/${stationId}`);

      return data
        .map(item => ({
          ...item,
          date: `${item.year}-${String(item.month).padStart(2, '0')}-${String(item.day).padStart(2, '0')}`,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    enabled: !!stationId,
  });
}

export {
  useStations,
  useMeasurements,
};