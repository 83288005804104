const productConfigs = {
  TERRASCOPE_S2_CHL_V1: {
    tileMode: 'canvas',
    colorScales: ['default', 'viridis', 'diverging', 'color blind'],
    scale: {
      min: 0,
      max: 2000,
    },
    noData: 65535,
    scalingFunction: (x) => {
      return x / 10;
    },
  },
  TERRASCOPE_S2_SPM_V1: {
    tileMode: 'canvas',
    colorScales: ['default', 'viridis', 'diverging', 'color blind'],
    scale: {
      min: 0,
      max: 2000,
    },
    noData: 65535,
    scalingFunction: (x) => {
      return x / 10;
    },
  },
  TERRASCOPE_S2_TUR_V1: {
    tileMode: 'canvas',
    colorScales: ['default', 'viridis', 'diverging', 'color blind'],
    scale: {
      min: 0,
      max: 2000,
    },
    noData: 65535,
    scalingFunction: (x) => {
      return x / 10;
    },
  },
  none: {},
}

export { productConfigs };
