import { usePrev } from '@services/hooks.service';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

function ProductWMTSLayer({ map }) {
  const activeMeasurement = useSelector(state => state.timeSeries.activeMeasurement);
  const activeDate = useSelector(state => state.timeSeries.activeDate);
  const measurementSelection = useSelector(state => state.timeSeries.measurementSelection);

  const prevDate = usePrev(activeDate);
  const prevActiveMeasurement = usePrev(activeMeasurement);

  const layer = useRef();

  useEffect(() => {
    const measurementInformation = measurementSelection.find(ms => ms.typeCode === activeMeasurement);

    if (map && activeDate && activeMeasurement && measurementInformation) {
      if (activeDate !== prevDate || activeMeasurement !== prevActiveMeasurement) {
        const wmtsUrl = (
          'https://services.terrascope.be/wmts/v2?Service=WMTS&Request=GetTile&Format=image/png'
          + '&Version=1.0.0&tilematrixset=EPSG:3857&TileMatrix=EPSG:3857:{z}&TileCol={x}&TileRow={y}'
          + `&layer=${measurementInformation.id}&TIME=${activeDate}&style=`
        );

        if (layer.current) {
          map.removeLayer(layer.current);
        }
  
        layer.current = L.tileLayer(wmtsUrl, { pane: 'product-layers' });
        layer.current.addTo(map);
      }
    }
  }, [map, activeMeasurement, activeDate, measurementSelection, prevDate, prevActiveMeasurement]);

  useEffect(() => {
    return () => {
      if (map && map.hasLayer(layer?.current)) {
        map.removeLayer(layer?.current);
      }
    };
  }, [map]);

  return null;
}

export default ProductWMTSLayer;