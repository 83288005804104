import { useTranslation } from '@utils/i18n/i18n';

import Radiogroup from '@App/components/Radiogroup/Radiogroup';

import { useLocalizedAdditionalOptions } from '@App/pages/Explore/explore.hooks';

import './extra-layers.styl';

function ExtraLayers(props) {
  const { t } = useTranslation();
  const { 
    additionalLayer, setAdditionalLayer,
  } = props;

  const additionalLayerOptions = useLocalizedAdditionalOptions();
  
  return (
    <div className="extra-layers">
      <div className="extra-layers__title">
        {t('show_extra_information')}
      </div>
      <Radiogroup
        name="additional-dynamic-options"
        className="extra-layers__radio-group"
        radios={additionalLayerOptions}
        selectedValue={additionalLayer}
        onChange={o => setAdditionalLayer(o)}
        showTooltips
      />
    </div>
  )
}

export default ExtraLayers;