import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@utils/i18n/i18n';

function useLocalizedDownloadOptions() {
  const { t, lang } = useTranslation();

  const getOptions = useCallback(() => [
    {
      label: `${t('chl-a')} (${t('chl-a_unit')})`,
      id: 'TERRASCOPE_S2_CHL_V1',
      translationKey: 'chl-a',
      tooltip: {
        text: t('chl-a_tooltip'),
        img: '/img/tooltips/chl-a.png',
      },
      color: '#e83a8b',
      typeCode: 'CHLA',
    },
    {
      label: `${t('tsm')} (${t('tsm_unit')})`,
      id: 'TERRASCOPE_S2_SPM_V1',
      translationKey: 'tsm',
      tooltip: {
        text: t('tsm_tooltip'),
      },
      color: '#52cc76',
      typeCode: 'TSS',
    },
    {
      label: `${t('turbidity')} (${t('turbidity_unit')})`,
      id: 'TERRASCOPE_S2_TUR_V1',
      translationKey: 'turbidity',
      tooltip: {
        text: t('turbidity_tooltip'),
      },
      color: '#993ae8',
      typeCode: 'TUR',
    },
  ], [t]);

  const [options, setOptions] = useState(getOptions);

  useEffect(() => {
    setOptions(getOptions());
  }, [lang, getOptions]);

  return options;
}

export { useLocalizedDownloadOptions };
