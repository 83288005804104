import { useState } from 'react';
import { Select } from '@nazka/nazka.mapframe.components';

import { generateUniqueId } from '@services/utils.service';
import { useLocalizedMeasurementTypeOptions } from '@App/pages/Explore/explore.hooks';
import { useTranslation } from '@utils/i18n/i18n';

import { getUniqueIndicators } from './station-marker-popup.utils';

import './station-marker-popup.styl';

function Popup({ station }) {
  const {
    site, stationId, stationType, altitude, measurements, 
  } = station?.properties || {};

  const [selectedMeasurementType, setSelectedMeasurementType] = useState();

  const { t } = useTranslation();

  const measurementLayerOptions = useLocalizedMeasurementTypeOptions();
  const uniqueIndicators = getUniqueIndicators(measurements);

  const options = uniqueIndicators.map((indicator) => {
    const measurementInfo = measurementLayerOptions.find(m => m?.typeCode === indicator);

    return {
      label: measurementInfo?.name,
      id: indicator,
    };
  });
  
  const selectedValue = selectedMeasurementType || options[0]?.id;

  const selectedMeasurements = measurements.filter(({ indicator }) => indicator === selectedValue);

  return (
    <div className="station-marker-popup">
      <div className="station-marker-popup__header">
        <h2>{`${site} (${stationId})`}</h2>
        <div className="station-marker-popup__header__info">
          <p>
            {t('station_type')}
            {': '}
            {`${stationType}`}
          </p>
          {altitude && (
            <p>{`Altitude: ${altitude} m`}</p>
          )}
        </div>
      </div>
      <Select
        className="station-marker-popup__select"
        options={options}
        value={selectedValue}
        onInputChange={item => setSelectedMeasurementType(item.id)}
      />
      <div className="station-marker-popup__content">
        {!measurements.length && (
          <p>No measurements available yet.</p>
        )}
        {selectedMeasurements.map((measurement) => {
          const {
            day, month, year, value, indicator,
          } = measurement || {};

          const measurementInfo = measurementLayerOptions.find(m => m?.typeCode === indicator);

          return (
            <div className="station-marker-popup__content__measurement" key={`${generateUniqueId()}`}>
              <div className="station-marker-popup__content__measurement__data">
                <span className="station-marker-popup__content__measurement__data__time">
                  {`${day ? `${String(day).padStart(2, '0')}/` : ''}`}
                  {`${month ? `${String(month).padStart(2, '0')}/` : ''}`}
                  {`${year || ''}`}
                </span>
                <span>
                  {`${value} ${measurementInfo?.unit || ''}`}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Popup;
