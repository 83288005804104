import { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from '@utils/i18n/i18n';
import IconButton from '@library/IconButton/IconButton';
import { faChevronLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import Legend from './Legend/Legend';
import ColorScaleSelector from './ColorScaleSelector/ColorScaleSelector';

import { productConfigs } from './legend-box.config';

import './legend-box.styl';

function LegendBox({ map, measurementType, date }) {
  const { t } = useTranslation();
  const [latLng, setLatLng] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [hoveredValue, sethoveredValue] = useState();
  const [activeColorScale, setActiveColorScale] = useState('default');

  const layerOptions = useMemo(() => productConfigs[measurementType?.value], [measurementType]);
  const { scale, colorScales } = layerOptions;
  
  // function getMousePos(canvas, evt) {
  //   const rect = canvas.getBoundingClientRect();
  //   return {
  //     x: evt.clientX - rect.left,
  //     y: evt.clientY - rect.top,
  //   };
  // }

  useEffect(() => {
    function setColorPicker(e) {
      setLatLng(e.latlng);
      // let canvases = document.querySelectorAll('.tiff-tile');

      // const zIndex = Math.max(
      //   ...Array.prototype.map.call(
      //     canvases, 
      //     canv => Number(canv.parentElement.parentElement.style.zIndex),
      //   ),
      // );
      // canvases = Array.prototype.filter.call(
      //   canvases, 
      //   canv => Number(canv.parentElement.parentElement.style.zIndex) === zIndex,
      // );

      // Object.keys(canvases).every((key) => {
      //   const canvas = canvases[key];
      //   const context = canvas.getContext('2d');
      //   const { plot } = canvas;
      //   const pos = getMousePos(canvas, e.originalEvent);

      //   if (pos.x >= 0 && pos.x <= 255 && pos.y >= 0 && pos.y <= 255 - 1 && plot) {
      //     const hoveredColorVal = context.getImageData(Math.round(pos.x), Math.round(pos.y), 1, 1);
      //     const rgbaVals = Array.from(hoveredColorVal.data);
      //     if (rgbaVals[3] !== 0) {
      //       document.getElementsByClassName('leaflet-map')[0].style.cursor = 'crosshair';
      //       const value = plot.atPoint(Math.round(pos.x), Math.round(pos.y));
      //       sethoveredValue(value !== plot.getNoDataValue() ? value : undefined);
      //       return false;
      //     }
      //   }

      //   document.getElementsByClassName('leaflet-map')[0].style.cursor = '';
      //   sethoveredValue(undefined);
      //   return true;
      // });
    }

    if (map) map.on('mousemove', setColorPicker);
    return () => map && map.off('mousemove', setColorPicker);
  }, [map]);

  useEffect(() => {
    if (map) map.fire('showscalecontrol', { visible: isOpen });
  }, [map, isOpen]);

  return (
    <div className={`legend-box${!isOpen ? ' legend-box--collapsed' : ''}`}>
      {!isOpen && (
        <IconButton 
          icon={faChevronLeft} 
          className="legend-box__content__info__open" 
          onClick={() => setIsOpen(!isOpen)} 
          title={!isOpen ? t('controls_color_scale_show') : t('controls_color_scale_hide')}
        />
      )}
      <div className="legend-box__content">
        <div className="legend-box__content__info">
          {
            `${measurementType?.translationKey ? t(measurementType?.translationKey) : ''}`
            + ` (${measurementType?.translationKey ? t(`${measurementType?.translationKey}_unit`) : ''})`
          }
          <div className="legend-box__content__info__buttons">
            <IconButton 
              icon={faTimes} 
              className="legend-box__content__info__buttons__button" 
              onClick={() => setIsOpen(!isOpen)} 
              title={!isOpen ? t('controls_color_scale_show') : t('controls_color_scale_hide')}
            />
          </div>
        </div>
        <div className="legend-box__content__data">
          <Legend
            showPointer
            hoveredValue={hoveredValue}
            scale={scale}
            layerId={measurementType?.value}
            colorScale={activeColorScale}
            layerOptions={layerOptions}
          />
        </div>
        {/* <div className="legend-box__content__extra">
          <div className="legend-box__content__extra__color-scale">
            {date && (
              <p className="legend-box__content__extra__color-scale__date">
                {format(new Date(date), t('date_format_long'))}
              </p>
            )}
            <label className="legend-box__content__extra__color-scale__label" title={t('controls_color_scale_choose')}>
              {`${t('controls_color_scale')}: `}
              <ColorScaleSelector 
                items={colorScales}
                activeItem={activeColorScale}
                anchor="bottom-right"
                onChange={item => setActiveColorScale(item)}
              />
            </label>
          </div>
          <div className="legend-box__content__extra__coords">
            <p>
              <span>{latLng?.lat ? Number(latLng.lat).toFixed(4) : '-'}</span>
              {' lat'}
            </p>
            <p>
              <span>{latLng?.lng ? Number(latLng.lng).toFixed(4) : '-'}</span>
              {' lon'}
            </p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default LegendBox;
