import axios from 'axios';

function fetchFile(href, onDownloadProgress) {
  return axios.get(href, { 
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      onDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    },
  })
}

export {
  fetchFile,
}