import { useTranslation } from '@utils/i18n/i18n';
import { initCookiesProps } from '@utils/Cookies/cookies.utils';

function useCookieProps(useConfig) {
  const { t } = useTranslation();

  return useConfig ? initCookiesProps(t) : {};
}

export {
  useCookieProps,
}