import { useState, useEffect } from 'react';
import { generateUniqueId } from '@services/utils.service';

import { I18nProvider } from '../i18n.context';

function TranslationsProvider(props) {
  const { translator, children } = props;

  const [lang, setLang] = useState(translator.getLanguage());
  const [isLoading, setIsLoading] = useState(translator.getLoading());

  useEffect(() => {
    const subscriptionId = generateUniqueId();

    const { loading, language } = translator.subscribe(subscriptionId, {
      onLanguageChange: setLang,
      onLoadingChange: setIsLoading,
    });

    setIsLoading(loading);
    setLang(language);

    if (import.meta.hot) {
      import.meta.hot.on(
        'locale-update',
        () => translator.setLanguage(translator.getLanguage()),
      );
    }

    return () => translator.unsubscribe(subscriptionId);
  }, [translator]);

  return (
    <I18nProvider 
      value={{
        lang,
        languages: translator.getLanguages(),
        setLanguage: language => translator.setLanguage(language),
        translate: (stringId, data = null) => translator.translate(stringId, data),
        isLoading,
      }}
    >
      {children}
    </I18nProvider>
  );
}

export default TranslationsProvider;