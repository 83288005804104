/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-lines */
import {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  format, parse, subMonths, addMonths,
} from 'date-fns';
import { useTranslation } from '@utils/i18n/i18n';
import {
  LineChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine, LabelList, Legend,
  ScatterChart, Scatter,
} from 'recharts';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import Icon from '@library/Icon/Icon';
import FileDownload from '@library/FileDownload/FileDownload';

import { checkForAndMoveOverlapingLabels, getDaysArray, nearestDate } from './time-series-graph.utils';

import './time-series-graph.styl';

const DATA_TYPES = ['CHLA', 'TSS', 'TUR'];

function TimeSeriesGraph({
  setActiveMeasurement,
  setActiveDate,
  uniqueDates,
  measurements,
}) {
  const { t } = useTranslation();

  const selectedStation = useSelector(state => state.timeSeries.station);
  const activeDate = useSelector(state => state.timeSeries.activeDate);
  const measurementSelection = useSelector(state => state.timeSeries.measurementSelection);
  const activeMeasurement = useSelector(state => state.timeSeries.activeMeasurement);
  const statisticalValueType = useSelector(state => state.timeSeries.statisticalValueType);
  const minValidPixelsPercentage = useSelector(state => state.timeSeries.minValidPixelsPercentage);

  const [nearestHoveredDateData, setNearestHoveredDateData] = useState();
  const [lastDate, setLastDate] = useState(new Date(uniqueDates[uniqueDates.length - 1]));
  const [firstDate, setFirstDate] = useState(() => subMonths(lastDate, 6));
  const [lastAvailableDateReached, setLastAvailableMaxDateReached] = useState(true);
  const [firstAvailableDateReached, setFirstAvailableMaxDateReached] = useState(false);

  // const activeWaterbodyFeature = useMemo(() => {
  //   const feature = null;

  //   return {
  //     ...feature,
  //     properties: {
  //       id: feature.properties.waterbodyId,
  //       description: feature.properties.description,
  //     },
  //   };
  // }, [selectedStation]);

  const allDatesInBetweenData = useMemo(() => {
    const allDatesInBetween = getDaysArray(firstDate, lastDate, 1).map(date => format(date, 'yyyy-MM-dd'));

    if (!allDatesInBetween.includes(format(lastDate, 'yyyy-MM-dd'))) {
      allDatesInBetween.push(format(lastDate, 'yyyy-MM-dd'));
    }

    return allDatesInBetween.map((date) => {
      const graphDataObject = { date: format(new Date(date), t('date_format_short')), showTooltip: true };

      DATA_TYPES.forEach((type) => {
        const dateData = measurements.find(m => m.date === date && m.indicator === type);

        graphDataObject.hasData = !graphDataObject.hasData ? !!dateData : graphDataObject.hasData;

        if (dateData) {
          graphDataObject.validPixelsPercentage = dateData.validCountPercentage;
          graphDataObject[type] = dateData;
          graphDataObject[`inactive_${type}`] = null;

          const measurementInfo = measurementSelection.find(ms => ms.typeCode === type);
          if (measurementInfo
            && (graphDataObject[type][statisticalValueType.id] > measurementInfo.maxValue
              || graphDataObject[type][statisticalValueType.id] < measurementInfo.minValue
              || dateData.validCountPercentage < minValidPixelsPercentage)) {
            graphDataObject[`inactive_${type}`] = { ...graphDataObject[type] };
            graphDataObject[type] = null;
          }
        }
      });
      graphDataObject.index = 1;
      return graphDataObject;
    });
  }, [
    measurementSelection,
    measurements,
    statisticalValueType,
    firstDate,
    lastDate,
    t,
    minValidPixelsPercentage,
  ]);

  const populatedDatesOnly = useMemo(() => {
    return allDatesInBetweenData.filter(d => d.hasData);
  }, [allDatesInBetweenData]);

  useEffect(() => {
    const firstAvailableDate = new Date(uniqueDates[0]);
    const lastAvailableDate = new Date(uniqueDates[uniqueDates.length - 1]);
    setLastAvailableMaxDateReached(lastAvailableDate <= lastDate);
    setFirstAvailableMaxDateReached(firstAvailableDate >= firstDate);
    if (firstAvailableDate > firstDate) setFirstDate(firstAvailableDate);
    if (lastAvailableDate < lastDate) setLastDate(lastAvailableDate);
  }, [firstDate, lastDate, uniqueDates]);

  // Check if tooltips overlap and move them if they do
  useEffect(() => {
    checkForAndMoveOverlapingLabels('.time-series-graph__tooltip');
  }, [selectedStation, measurementSelection, statisticalValueType.id, activeDate]);

  useEffect(() => {
    checkForAndMoveOverlapingLabels('.time-series-graph__tooltip');
    checkForAndMoveOverlapingLabels('.time-series-graph__tooltip-hover');
  }, [nearestHoveredDateData]);

  const onHover = useCallback((date) => {
    if (!date) {
      setNearestHoveredDateData(null);
    } else {
      const nearestPopulatedDateIndex = nearestDate(
        populatedDatesOnly.map(d => parse(d.date, t('date_format_short'), new Date())),
        parse(date, t('date_format_short'), new Date()),
      );
      setNearestHoveredDateData(populatedDatesOnly[nearestPopulatedDateIndex]);
    }
  }, [t, populatedDatesOnly]);

  const activeDateStringShort = useMemo(() => {
    if (!activeDate) return null;
    return format(new Date(activeDate), t('date_format_short'));
  }, [t, activeDate]);

  const activeDateStringLong = useMemo(() => {
    if (!activeDate) return null;
    return format(new Date(activeDate), t('date_format_long'));
  }, [t, activeDate]);

  const hoveredDateStringShort = useMemo(() => {
    if (!nearestHoveredDateData?.date) return null;
    return nearestHoveredDateData.date;
  }, [nearestHoveredDateData]);

  return (
    <div className="time-series-graph">
      <div className="time-series-graph__header">
        {activeDateStringLong && (
          <div className="time-series-graph__header__date">
            {activeDateStringLong}
          </div>
        )}
        <div className="time-series-graph__header__date-range">
          <Icon
            icon={faChevronLeft}
            size={15}
            className={
              'time-series-graph__header__date-range__icon'
              + `${firstAvailableDateReached ? ' time-series-graph__header__date-range__icon--disabled' : ''}`
            }
            onClick={() => {
              if (!firstAvailableDateReached) {
                setLastDate(subMonths(lastDate, 1));
                setFirstDate(subMonths(firstDate, 1));
              }
            }}
          />
          <p className="time-series-graph__header__date-range__text">
            {`${format(firstDate, t('date_format_short'))} - ${format(lastDate, t('date_format_short'))}`}
          </p>
          <Icon
            icon={faChevronRight}
            size={15}
            className={
              'time-series-graph__header__date-range__icon'
              + `${lastAvailableDateReached ? ' time-series-graph__header__date-range__icon--disabled' : ''}`
            }
            onClick={() => {
              if (!lastAvailableDateReached) {
                setLastDate(addMonths(lastDate, 1));
                setFirstDate(addMonths(firstDate, 1));
              }
            }}
          />
        </div>
        <FileDownload 
          blob={new Blob(
            [JSON.stringify(measurements, null, '\t')],
            { type: 'application/json' },
          )}
          filename={`times_series_${selectedStation?.description?.toLowerCase()}.json`} 
          className="time-series-graph__header__download"
        >
          <div className="time-series-graph__header__download">
            {t('time_series_download_data')}
          </div>
        </FileDownload>
      </div>
      <div style={{ position: 'relative', zIndex: 5 }}>
        <ResponsiveContainer width="100%" height={30}>
          <ScatterChart
            margin={{
              top: 13, right: 35, left: 35, bottom: 0,
            }}
          >
            <XAxis
              dataKey="date"
              scale="point"
              interval={0}
              height={0.15}
              tick={{ fontSize: 0 }}
              ticks={populatedDatesOnly.map(d => d.date)}
              tickLine={{ transform: 'translate(0, -6)', strokeDasharray: '1 0' }}
              stroke="#ccc"
              strokeDasharray="3 3"
            />
            <YAxis
              type="number"
              dataKey="index"
              width={0}
              tick={false}
              tickLine={false}
              axisLine={false}
            />
            <Scatter
              data={allDatesInBetweenData}
              isAnimationActive={false}
              shape={(props) => {
                const {
                  cx, y, validPixelsPercentage, hasData,
                } = props;

                if (!hasData) return null;

                return (
                  <foreignObject x={cx - 15} y={y} width="30" height="10">
                    <p
                      style={{ textAlign: 'center', lineHeight: 1.15, fontSize: 10.5 }}
                      title={t('time_series_ratio')}
                    >
                      {validPixelsPercentage && parseFloat(validPixelsPercentage.toFixed(1))}
                      %
                    </p>
                  </foreignObject>
                );
              }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <ResponsiveContainer width="100%" height={310}>
        <LineChart
          data={allDatesInBetweenData}
          cursor="pointer"
          margin={{
            top: 30, right: 35, left: 35, bottom: 0,
          }}
          onClick={() => {
            if (nearestHoveredDateData) {
              const newActiveDate = parse(nearestHoveredDateData.date, t('date_format_short'), new Date());
              setActiveDate(newActiveDate);
            }
          }}
          onMouseMove={o => onHover(o?.activeLabel)}
          onMouseLeave={() => onHover(null)}
        >
          <Legend
            verticalAlign="bottom"
            height={28}
            payload={measurementSelection.map((m) => {
              return {
                value: m.label, type: 'circle', id: m.typeCode, color: m.color,
              }
            })}
          />
          {populatedDatesOnly.map(({ date }) => (
            <ReferenceLine
              key={date}
              x={date}
              strokeDasharray="3 3"
            />
          ))}
          <ReferenceLine
            x={activeDateStringShort}
            stroke="#4191E2"
            strokeWidth={1.5}
          />
          {hoveredDateStringShort && (
            <ReferenceLine
              x={hoveredDateStringShort}
              stroke="#4191E2"
              strokeWidth={1.5}
              strokeDasharray="3 3"
            />
          )}
          <XAxis
            dataKey="date"
            interval="preserveStartEnd"
            tick={(props) => {
              const { x, y, payload } = props;
              return (
                <text
                  x={x}
                  y={y}
                  dy={16}
                  fill="#555"
                  fontSize={13}
                  fontWeight={400}
                  textAnchor="middle"
                  style={{ userSelect: 'none', transform: 'translateY(-2px)' }}
                >
                  {payload.value}
                </text>
              );
            }}
          />
          <YAxis tick={false} width={0} padding={{ bottom: 5 }} />
          <ReferenceLine
            x={activeDateStringShort}
            strokeOpacity={0}
            label={(props) => {
              const { viewBox } = props;
              return (
                <foreignObject x={viewBox.x - (70 / 2)} y={258} width="70" height="24">
                  <p className="time-series-graph__custom-label-active">
                    {activeDateStringShort}
                  </p>
                </foreignObject>
              )
            }}
          />
          {nearestHoveredDateData && activeDateStringShort !== hoveredDateStringShort && (
            <ReferenceLine
              x={hoveredDateStringShort}
              strokeOpacity={0}
              label={(props) => {
                const { viewBox } = props;
                return (
                  <foreignObject x={viewBox.x - (70 / 2)} y={258} width="70" height="24">
                    <p className="time-series-graph__custom-label">
                      {hoveredDateStringShort}
                    </p>
                  </foreignObject>
                )
              }}
            />
          )}
          {/* actual line */}
          {measurementSelection.map(m => (
            <Line
              key={m.typeCode}
              id={m.typeCode}
              type="linear"
              dataKey={`${m.typeCode}.${statisticalValueType.id}`}
              stroke={m.color}
              strokeWidth={0}
              onClick={() => setActiveMeasurement(m.typeCode)}
              dot={{
                strokeWidth: 0, r: 4, fill: m.color,
              }}
              connectNulls
              activeDot={false}
              isAnimationActive={false}
            />
          ))}
          {/* hovered tooltips */}
          {measurementSelection.map(m => (
            <Line
              key={m.typeCode}
              type="monotone"
              dataKey={`${m.typeCode}.${statisticalValueType.value}`}
              strokeWidth={0}
              isAnimationActive={false}
              dot={false}
            >
              <LabelList
                position="right"
                content={(props) => {
                  const { x, y, value } = props;
                  const selectedData = allDatesInBetweenData[props.index];
                  const isOneOfLast = allDatesInBetweenData.length - 4 < props.index;
                  const { date } = selectedData;

                  if (date !== activeDateStringShort || (!value && value !== 0)) return null;

                  const unit = m.translationKey !== 'tsi' ? t(`${m.translationKey}_unit`) : `(${t(`${m.translationKey}_unit`)})`;
                  const isActiveMeasurement = m.typeCode === activeMeasurement;
                  const height = 20;
                  const width = 80;
                  const spacing = 6;

                  return (
                    <g>
                      <foreignObject
                        x={isOneOfLast ? (x - width - spacing) : (x + spacing)}
                        y={y - (height / 2)}
                        width={width}
                        height={height}
                      >
                        <div
                          className={`time-series-graph__tooltip${isActiveMeasurement ? ' active' : ''}`}
                          onClick={() => setActiveMeasurement(m.typeCode)}
                          style={
                            isActiveMeasurement ? { background: m.color, color: 'white' }
                              : { background: 'white', color: m.color }
                          }
                        >
                          <p className="time-series-graph__tooltip__text">
                            {`${Number(value).toFixed(1)} ${unit}`}
                          </p>
                        </div>
                      </foreignObject>
                    </g>
                  );
                }}
              />
            </Line>
          ))}
          {/* active tooltips */}
          {measurementSelection.map(m => (
            <Line
              key={m.typeCode}
              type="monotone"
              dataKey={`${m.typeCode}.${statisticalValueType.value}`}
              strokeWidth={0}
              isAnimationActive={false}
              dot={false}
            >
              <LabelList
                position="right"
                content={(props) => {
                  const { x, y, value } = props;
                  const selectedData = allDatesInBetweenData[props.index];
                  const isOneOfLast = allDatesInBetweenData.length - 4 < props.index;
                  const { date } = selectedData;

                  if (date !== hoveredDateStringShort
                    || date === activeDateStringShort
                    || (!value && value !== 0)) return null;

                  const unit = m.translationKey !== 'tsi' ? t(`${m.translationKey}_unit`) : `(${t(`${m.translationKey}_unit`)})`;
                  const height = 20;
                  const width = 80;
                  const spacing = 6;

                  return (
                    <g>
                      <foreignObject
                        x={isOneOfLast ? (x - width - spacing) : (x + spacing)}
                        y={y - (height / 2)}
                        width={width}
                        height={height}
                      >
                        <div
                          className="time-series-graph__tooltip-hover"
                          onClick={() => setActiveMeasurement(m.typeCode)}
                          style={{ background: 'white', color: m.color }}
                        >
                          <p className="time-series-graph__tooltip-hover__text">
                            {`${Number(value).toFixed(1)} ${unit}`}
                          </p>
                        </div>
                      </foreignObject>
                    </g>
                  );
                }}
              />
            </Line>
          ))}
          {/* dots of filtered values */}
          {measurementSelection.map(m => (
            <Line
              key={`inactive_${m.typeCode}`}
              type="monotone"
              dataKey={`inactive_${m.typeCode}.${statisticalValueType.value}`}
              stroke="transparent"
              strokeWidth={2}
              dot={{ stroke: m.color, strokeWidth: 2 }}
              activeDot={false}
              isAnimationActive={false}
            >
              <LabelList
                position="right"
                content={(props) => {
                  const { x, y, value } = props;
                  const selectedData = allDatesInBetweenData[props.index];
                  const isOneOfLast = allDatesInBetweenData.length - 4 < props.index;
                  const { date } = selectedData;

                  if (date !== activeDateStringShort || (!value && value !== 0)) return null;

                  const unit = m.translationKey !== 'tsi' ? t(`${m.translationKey}_unit`) : `(${t(`${m.translationKey}_unit`)})`;
                  const isActiveMeasurement = m.typeCode === activeMeasurement;
                  const height = 20;
                  const width = 80;
                  const spacing = 6;

                  return (
                    <g>
                      <foreignObject
                        x={isOneOfLast ? (x - width - spacing) : (x + spacing)}
                        y={y - (height / 2)}
                        width={width}
                        height={height}
                      >
                        <div
                          className={`time-series-graph__tooltip${isActiveMeasurement ? ' active' : ''}`}
                          onClick={() => setActiveMeasurement(m.typeCode)}
                          style={
                            isActiveMeasurement ? { background: m.color, color: 'white' }
                              : { background: 'white', color: m.color }
                          }
                        >
                          <p className="time-series-graph__tooltip__text">
                            {`${Number(value).toFixed(1)} ${unit}`}
                          </p>
                        </div>
                      </foreignObject>
                    </g>
                  );
                }}
              />
            </Line>
          ))}
          {/* disabled active tooltips */}
          {measurementSelection.map(m => (
            <Line
              key={`inactive_${m.typeCode}`}
              type="monotone"
              dataKey={`inactive_${m.typeCode}.${statisticalValueType.value}`}
              strokeWidth={0}
              isAnimationActive={false}
              dot={false}
            >
              <LabelList
                position="right"
                content={(props) => {
                  const { x, y, value } = props;
                  const selectedData = allDatesInBetweenData[props.index];
                  const isOneOfLast = allDatesInBetweenData.length - 4 < props.index;
                  const { date } = selectedData;

                  if (date !== hoveredDateStringShort
                    || date === activeDateStringShort
                    || (!value && value !== 0)) return null;

                  const unit = m.translationKey !== 'tsi' ? t(`${m.translationKey}_unit`) : `(${t(`${m.translationKey}_unit`)})`;
                  const height = 20;
                  const width = 80;
                  const spacing = 6;

                  return (
                    <g>
                      <foreignObject
                        x={isOneOfLast ? (x - width - spacing) : (x + spacing)}
                        y={y - (height / 2)}
                        width={width}
                        height={height}
                      >
                        <div
                          className="time-series-graph__tooltip-hover"
                          onClick={() => setActiveMeasurement(m.typeCode)}
                          style={{ background: 'white', color: m.color }}
                        >
                          <p className="time-series-graph__tooltip-hover__text">
                            {`${Number(value).toFixed(1)} ${unit}`}
                          </p>
                        </div>
                      </foreignObject>
                    </g>
                  );
                }}
              />
            </Line>
          ))}
        </LineChart>
      </ResponsiveContainer>
      <div className="time-series-graph__footer" />
    </div>
  )
}

export default TimeSeriesGraph;