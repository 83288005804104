import { useSelector } from 'react-redux';

import ProductWMTSLayer from './ProductWMTSLayer/ProductWMTSLayer';
import TrueColorImageLayer from './TrueColorImageLayer/TrueColorImageLayer';
import TileFootprintsLayer from './TileFootprintsLayer/TileFootprintsLayer';
import CloudMaskLayer from './CloudMaskLayer/CloudMaskLayer';
import WaterMaskLayer from './WaterMaskLayer/WaterMaskLayer';

function DataLayers(props) {
  const additionalLayer = useSelector(state => state.explore.additionalLayer);
  const date = useSelector(state => state.explore.date);

  return (
    <>
      <ProductWMTSLayer {...props} />
      {additionalLayer === 'TERRASCOPE_S2_RHOW_V1' && date && (
        <TrueColorImageLayer date={date?.split('T')[0]} layerId={additionalLayer} {...props} />
      )}
      {additionalLayer === 'TILE_FOOTPRINTS' && date && (
        <TileFootprintsLayer date={date} {...props} />
      )}
      <CloudMaskLayer {...props} />
      <WaterMaskLayer {...props} />
    </>
  )
}

export default DataLayers;