import { useTranslation } from '@utils/i18n/i18n';

import Sidepanel from '@App/components/Sidepanel/Sidepanel';
import ExploreForm from './ExploreForm/ExploreForm';
import ExploreMap from './ExploreMap/ExploreMap';

import './explore.styl';

function Explore() {
  const { t } = useTranslation();

  return (
    <div className="explore">
      <Sidepanel 
        className="explore__sidepanel"
        title={t('explore')}
        icon="img/pages/explore-icon.svg"
        intro={t('explore_intro_text')}
      >
        <ExploreForm />
      </Sidepanel>
      <ExploreMap />
    </div>
  );
}

export default Explore;