import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@nazka/nazka.mapframe.components';
import { QueryClientProvider } from '@tanstack/react-query';
import TranslationsProvider from '@utils/i18n/i18n';

import AppWindow from '@utils/AppWindow/AppWindow';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import Cookies from '@utils/Cookies/Cookies';

import Navbar from '@App/components/Navbar/Navbar';

import routes from '@App/app.routes';
import store from '@App/app.store';
import translator from '@App/app.translator';
import queryClient from '@utils/QueryClient/QueryClient';

import theme from '@css/theme.style.json';

import 'leaflet/dist/leaflet.css';
import './app.styl';

function App() {
  useEffect(() => document.documentElement.setAttribute('data-app-initialised', true), []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ErrorBoundary>
              <TranslationsProvider translator={translator}>
                <Navbar />
                <Routes>
                  {routes.map(({ path, element }) => (
                    <Route path={path} element={element} key={path} />
                  ))}
                </Routes>
                <AppWindow />
                <Cookies useConfig className="cookies" />
              </TranslationsProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
