import 'leaflet';

import {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LegendBox from '@App/components/LegendBox/LegendBox';
import TrueColorImageLayer from '@App/pages/Explore/ExploreMap/DataLayers/TrueColorImageLayer/TrueColorImageLayer';
import TileFootprintsLayer from '@App/pages/Explore/ExploreMap/DataLayers/TileFootprintsLayer/TileFootprintsLayer';
import StationMarkers from '@App/components/StationMarkers/StationMarkers';

import { createStationOption } from '@services/utils.service';

import Controls from './Controls/Controls';
import TimeSeriesInfoBox from '../TimeSeriesInfoBox/TimeSeriesInfoBox';
import ProductWMTSLayer from './ProductWMTSLayer/ProductWMTSLayer';

import { mapFactory, addLayersFromConfigToMap } from './time-series-map.factory';
import { setMapBounds, setActiveMeasurement, setStation } from '../time-series.slice';
import { useLocalizedDownloadOptions } from '../time-series.hooks';

import 'leaflet/dist/leaflet.css';
import './time-series-map.styl';

function TimeSeriesMap() {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const activeDate = useSelector(state => state.timeSeries.activeDate);
  const measurementSelection = useSelector(state => state.timeSeries.measurementSelection);
  const activeMeasurement = useSelector(state => state.timeSeries.activeMeasurement);
  const additionalLayer = useSelector(state => state.timeSeries.additionalLayer);
  const selectedStation = useSelector(state => state.timeSeries.station);

  const measurementOptions = useLocalizedDownloadOptions();

  useEffect(() => {
    const mapInstance = mapFactory(mapRef.current);

    function updateMapBounds() {
      const bounds = mapInstance.getBounds();
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();

      dispatch(setMapBounds([southWest.lng, southWest.lat, northEast.lng, northEast.lat]));
    }

    updateMapBounds();
    setMap(mapInstance);

    mapInstance.on('moveend', updateMapBounds);

    return () => mapInstance.remove();
  }, [dispatch]);

  useEffect(() => {
    if (!map) return;
    addLayersFromConfigToMap(map);
  }, [map]);

  useEffect(() => {
    if (!measurementSelection.find(m => m?.typeCode === activeMeasurement)) {
      if (measurementSelection.length) {
        dispatch(setActiveMeasurement(measurementSelection[0].typeCode));
      } else {
        dispatch(setActiveMeasurement(undefined));
      }
    }
  }, [dispatch, measurementSelection, activeMeasurement]);

  useEffect(() => {
    if (!map) return;
    if (measurementSelection.length && activeMeasurement) {
      map.fire('no-measurement-layer', { moveUp: true });
    } else map.fire('no-measurement-layer', { moveUp: false });
  }, [activeMeasurement, map, measurementSelection.length]);

  const onMarkerClick = useCallback((station) => {
    dispatch(setStation(createStationOption(station)));
  }, [dispatch]);

  return (
    <div ref={mapRef} className="leaflet-map time-series-map">
      <Controls map={map} />
      {!!measurementSelection.length && (
        <TimeSeriesInfoBox
          measurementSelection={measurementSelection}
          activeMeasurement={activeMeasurement}
          setActiveMeasurement={v => dispatch(setActiveMeasurement(v))}
          activeDate={activeDate}
        />
      )}
      {(measurementSelection.length && activeMeasurement) && (
        <LegendBox
          map={map}
          measurementType={{
            ...measurementOptions.find(o => o.typeCode === activeMeasurement),
            value: measurementOptions.find(o => o.typeCode === activeMeasurement).id,
          }}
          date={activeDate}
        />
      )}
      {activeMeasurement && activeDate && (
        <ProductWMTSLayer map={map} />
      )}
      {additionalLayer === 'TERRASCOPE_S2_RHOW_V1' && activeDate && (
        <TrueColorImageLayer map={map} date={activeDate} layerId={additionalLayer} />
      )}
      {additionalLayer === 'TILE_FOOTPRINTS' && activeDate && (
        <TileFootprintsLayer map={map} date={activeDate} />
      )}
      <StationMarkers map={map} onMarkerClick={onMarkerClick} selectedStation={selectedStation} />
    </div>
  );
}

export default TimeSeriesMap;
