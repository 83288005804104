import { faArrowDownToLine, faArrowUpRightFromSquare, faCabinetFiling } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@nazka/nazka.mapframe.components';

import { useTranslation } from '@utils/i18n/i18n';
import { useDocuments, useWebLinks } from '@utils/QueryClient/download.queries';

import './files.styl';

function Files() {
  const { t } = useTranslation();

  const { data: webLinks, isLoading: isLoadingWebLinks } = useWebLinks();
  const { data: documents, isLoading: isLoadingDocuments } = useDocuments();

  return (
    <div className="files">
      <div className="files__title">
        <Icon className="files__title__icon" icon={faCabinetFiling} />
        {t('files_title')}
      </div>
      <div className="files__content">
        <h3>
          {t('files_files')}
        </h3>
        <p>
          {t('files_files_intro')}
        </p>
        <div className={`files__grid${isLoadingDocuments ? ' is-loading' : ''}`}>
          {documents?.map(({ name, title }) => {
            const downloadPath = `/api/download/documents/${name}`;

            return (
              <div className="files__grid__card" onClick={() => window.open(downloadPath, '_blank')}>
                <div className="files__grid__card__title">
                  {title || name}
                  <Icon className="files__grid__card__title__icon" icon={faArrowDownToLine} size="30px" />
                </div>
                <div>
                  <a href={downloadPath} target="_blank" rel="noreferrer">
                    {name}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <h3>
          {t('files_weblinks')}
        </h3>
        <p>
          {t('files_weblinks_intro')}
        </p>
        <div className={`files__grid${isLoadingWebLinks ? ' is-loading' : ''}`}>
          {webLinks?.map(({ title, url }) => {
            return (
              <div className="files__grid__card" onClick={() => window.open(url, '_blank')}>
                <div className="files__grid__card__title">
                  {title}
                  <Icon className="files__grid__card__title__icon" icon={faArrowUpRightFromSquare} size="24px" />
                </div>
                <div>
                  <a href={url} target="_blank" rel="noreferrer">
                    {url}
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Files;