/* eslint-disable max-lines */
import { useState } from 'react';
import { useTranslation } from '@utils/i18n/i18n';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import Portal from '@utils/Portal/Portal';
import Tooltip from '@App/components/Tooltip/Tooltip';
import ImageTooltip from '@App/components/Tooltip/ImageTooltip/ImageTooltip';
import { Icon } from '@nazka/nazka.mapframe.components';

import DateRangePicker from '@App/components/DateRangePicker/DateRangePicker';
import PopupWindow from '@App/components/PopupWindow/PopupWindow';

import FormTile from './SelectedTile/SelectedTile';

import { getDatePickerDates } from './selected-tiles.utils';

import './selected-tiles.styl';

function SelectedTiles({
  tiles,
  dateRangeAnchorBottom = false,
  onDateRangePickerSubmit,
}) {
  const { t } = useTranslation();
  const [highlightedPreview, setHighlightedPreview] = useState();

  const datesForDatePicker = getDatePickerDates(tiles);

  return (
    <div className="form-tiles">
      <span className="form-tiles__area">
        <span>{t('tiles')}</span>
        <div className="form-tiles__area__actions">
          {/* <DateRangePicker 
            availableDates={datesForDatePicker} 
            className="form-tile__header__buttons__button"
            onSubmit={onDateRangePickerSubmit}
            ancherBottom={dateRangeAnchorBottom}
            disabled={tiles.length <= 0}
            tooltip={t('download_date_range_tootip')}
            customOffeset="-35px"
          /> */}
          <Tooltip
            place="right"
            id="form-tiles-info"
            tooltip={(
              <ImageTooltip 
                text={t('download_only_dates_after')} 
              />
            )}
          >
            <Icon 
              icon={faInfoCircle} 
              className="form-tiles__area__info" 
              rootElProps={{ 
                'data-tooltip-id': 'form-tiles-info',
              }}
            />
          </Tooltip>
        </div>
      </span>
      <div className="form-tiles__scroll">
        {!tiles.length && (
          <div>{t('tiles_no_selected')}</div>
        )}
        {tiles.map((tile) => {
          const { tileid } = tile.properties;
          const tileData = tiles.find(tileDates => tileDates.tileid === tileid);

          const dates = tileData?.dates || [];

          return (
            <FormTile
              key={tile.properties.tileid}
              tile={tile}
              dates={dates}
              showPreview={preview => setHighlightedPreview(preview)}
            />
          );
        })}
      </div>
      {highlightedPreview && (
        <Portal>
          <PopupWindow
            closeAction={() => setHighlightedPreview(undefined)}
            title={`${Math.round(highlightedPreview.cloudCover)}% ${t('cloud_cover')}`}
          >
            <img src={highlightedPreview.url} className="form-tiles__preview__image" />
          </PopupWindow>
        </Portal>
      )}
    </div>
  );
}

export default SelectedTiles;