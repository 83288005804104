/* eslint-disable max-lines */
import React, { useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Select } from '@nazka/nazka.mapframe.components';

import Checkbox from '@App/components/Checkbox/Checkbox';
import Tooltip from '@App/components/Tooltip/Tooltip';
import ImageTooltip from '@App/components/Tooltip/ImageTooltip/ImageTooltip';
import MeasurementSlider from '@App/components/MeasurementSlider/MeasurementSlider';
import ExtraLayers from '@App/components/ExtraLayers/ExtraLayers';

import { useLocalizedDownloadOptions } from '@App/pages/TimeSeries/time-series.hooks';
import { useTranslation } from '@utils/i18n/i18n';
import { useMeasurements, useStations } from '@services/query.service';
import { createStationOption } from '@services/utils.service';

import TimeSeriesFormWaterbodySelector from './TimeSeriesFormWaterbodySelector/TimeSeriesFormWaterbodySelector';
import TimeSeriesFormValidPixelsSlider from './TimeSeriesFormValidPixelsSlider/TimeSeriesFormValidPixelsSlider';

import {
  setStation,
  setMeasurementSelection,
  setStatisticalValueType,
  setMinValidPixelsPercentage,
  setAdditionalLayer,
} from '../time-series.slice';
import { getMaxValue } from './time-series-form.utils';

import './time-series-form.styl';

function ExploreForm() {
  const prevMeasurementSelection = useRef([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const measurementOptions = useLocalizedDownloadOptions();

  const station = useSelector(state => state.timeSeries.station);
  const measurementSelection = useSelector(state => state.timeSeries.measurementSelection);
  const statisticalValueType = useSelector(state => state.timeSeries.statisticalValueType);
  const minValidPixelsPercentage = useSelector(state => state.timeSeries.minValidPixelsPercentage);
  const additionalLayer = useSelector(state => state.timeSeries.additionalLayer);

  const { data: stations } = useStations();
  const { data: measurements } = useMeasurements(station?.id);

  const stationOptions = useMemo(() => {
    if (!stations) {
      return [];
    }

    return stations.map(createStationOption);
  }, [stations]);

  useEffect(() => {
    prevMeasurementSelection.current = measurementSelection;
  }, [measurementSelection]);

  useEffect(() => {
    if (prevMeasurementSelection.current) {
      const updateMeasurementSelection = prevMeasurementSelection.current.map((m) => {
        const maxValue = getMaxValue(measurements, m.typeCode, statisticalValueType?.id);
        return { ...m, maxValue };
      });

      dispatch(setMeasurementSelection(updateMeasurementSelection));
    }
  }, [dispatch, measurements, statisticalValueType]);

  return (
    <div className="time-series-form">
      <TimeSeriesFormWaterbodySelector
        className="time-series-form__select"
        value={station}
        onInputChange={v => dispatch(setStation(v))}
        options={stationOptions}
        placeholder={t('dropdown_placeholder')}
      />
      <label className="time-series-form__label">
        <span className="time-series-form__label__text">
          {t('statistic')}
        </span>
        <Select
          className="time-series-form__select"
          value={statisticalValueType}
          onInputChange={v => dispatch(setStatisticalValueType(v))}
          placeholder={t('dropdown_placeholder')}
          options={[
            { label: 'Mean', id: 'mean' },
            { label: 'Median', id: 'median' },
            { label: 'Minimum', id: 'min' },
            { label: 'Maximum', id: 'max' },
            { label: 'Standard deviation', id: 'std' },
            { label: 'Q1', id: 'q25' },
            { label: 'Q3', id: 'q75' },
            { label: 'Pixel value', id: 'pixel' },
          ]}
        />
      </label>
      <div className="time-series-form__label">
        <span className="time-series-form__label__text">
          {t('download_data_types')}
        </span>
        {measurementOptions.map((option) => {
          const measurement = measurementSelection.find(({ id }) => id === option.id);
          const maxValue = getMaxValue(measurements, option?.typeCode, statisticalValueType?.id);

          return (
            <div key={option.id} className="time-series-form__option">
              <Checkbox
                className="time-series-form__option__checkbox"
                label={option.label}
                tooltip={option.tooltip}
                checked={measurementSelection.find(m => m.id === option.id)}
                onChange={(_, isChecked) => {
                  dispatch(setMeasurementSelection(isChecked
                    ? [...measurementSelection, { ...option, minValue: 0, maxValue }]
                    : measurementSelection.filter(ms => ms.id !== option.id)))
                }}
              />
              {measurement && (
                <MeasurementSlider
                  min={0}
                  max={maxValue}
                  minValue={measurement.minValue}
                  maxValue={measurement.maxValue}
                  onChange={v => dispatch(setMeasurementSelection([
                    ...measurementSelection.filter(ms => ms.id !== option.id),
                    { ...measurement, minValue: v[0], maxValue: v[1] },
                  ]))}
                  className="time-series-form__option__slider"
                  measurement={option}
                  color={option.color}
                />
              )}
            </div>
          )
        })}
      </div>
      <label className="time-series-form__label" id="guided-tour-valid-pixels">
        <span className="time-series-form__label__text">
          {t('time_series_valid_pixels_label')}
          <Tooltip
            place="right"
            id="time-series_valid-pixels_tooltip"
            tooltip={(<ImageTooltip text={t('time_series_valid_pixels_tooltip')} />)}
          >
            <Icon
              icon={faInfoCircle}
              className="time-series-form__label-tooltip"
              rootElProps={{
                'data-tooltip-id': 'time-series_valid-pixels_tooltip',
              }}
            />
          </Tooltip>
        </span>
        <TimeSeriesFormValidPixelsSlider
          min={0}
          max={100}
          value={minValidPixelsPercentage}
          setValue={v => dispatch(setMinValidPixelsPercentage(v))}
        />
      </label>
      <ExtraLayers
        additionalLayer={additionalLayer}
        setAdditionalLayer={v => dispatch(setAdditionalLayer(v))}
      />
    </div>
  )
}

export default ExploreForm;