import React from 'react';
import { useTranslation } from '@utils/i18n/i18n';
import { Select } from '@nazka/nazka.mapframe.components';

import './time-series-form-waterbody-selector.styl';

function TimeSeriesFormWaterbodySelector(props) {
  const { t } = useTranslation();

  return (
    <div className="time-series-form__label">
      <div className="time-series-form__label__header">
        <span className="time-series-form__label__text">
          {t('time_series_waterbody')}
        </span>
      </div>
      <Select 
        {...props}
        className={`select${props.className ? ` ${props.className}` : ''}`}
      />
    </div>
  )
}

export default TimeSeriesFormWaterbodySelector;
