import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Explore from '@App/pages/Explore/Explore';
import TimeSeries from '@App/pages/TimeSeries/TimeSeries';
import Download from '@App/pages/Download/Download';
import Files from '@App/pages/Files/Files';
import { faCabinetFiling } from '@fortawesome/pro-light-svg-icons';

function Redirect({ to }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, [navigate, to]);

  return null;
}

const routes = [
  {
    path: '/', 
    element: <Redirect to="/explore" />,
  },
  {
    name: 'Explore', 
    key: 'explore',
    path: '/explore', 
    element: <Explore />,
    navPosition: 'top',
    icon: '/img/nav/explore-icon.svg',
  },
  {
    name: 'Time series',
    key: 'time_series',
    path: '/time-series', 
    element: <TimeSeries />,
    navPosition: 'top',
    icon: '/img/nav/time-series-icon.svg',
  },
  {
    name: 'Download',
    key: 'download',
    path: '/download', 
    element: <Download />,
    navPosition: 'top',
    icon: '/img/nav/download-icon.svg',
  },
  {
    name: 'Files',
    key: 'files',
    path: '/files',
    element: <Files />,
    navPosition: 'top',
    icon: faCabinetFiling,
  },
];

export default routes;
