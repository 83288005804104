// added 0.5 value with its apropiate color as it's needed for the max value of the color legend 
// (used https://stackoverflow.com/a/14483027 to calculate the color but any mix function will do)

export const colorscales = {
  default: {
    colors: ['#5694ba', '#abdda4', '#f1e342', '#fdae61', '#a822b1', '#5500a8', '#d7191c', '#b41618', '#0a0a0a'],
    positions: [0, 0.024, 0.048, 0.1, 0.2, 0.3, 0.4, 0.5, 1],
  },
  viridis: { 
    colors: ['#225ea8', '#1d91c0', '#40b5c4', '#7fcdbb', '#a3dbb7', '#c7e9b4', '#ccebb4', '#d1edb3', '#edf8b1'],
    positions: [0, 0.024, 0.048, 0.1, 0.2, 0.3, 0.4, 0.5, 1],
  },
  diverging: {
    colors: ['#5694ba', '#8aaec8', '#b8c9d7', '#e5e5e5', '#eeab9e', '#e86f5b', '#d7191c', '#b41618', '#0a0a0a'],
    positions: [0, 0.024, 0.048, 0.1, 0.2, 0.3, 0.4, 0.5, 1],
  },
  'color blind': {
    colors: ['#5694ba', '#8bcecc', '#b8e9ce', '#ffffc3', '#ffc8a2', '#fa8e66', '#e65435', '#e0452f', '#c40014'],
    positions: [0, 0.024, 0.048, 0.1, 0.2, 0.3, 0.4, 0.5, 1],
  },
};
