import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CookieConsent } from '@nazka/nazka.mapframe.components';

import { useCookieProps } from '@utils/Cookies/cookies.hooks';
import { setCookieValue } from '@utils/Cookies/cookies.slice';
import { createCookieServiceLog } from '@utils/Cookies/cookies.service';
import { useTranslation } from '@utils/i18n/i18n';

function Cookies(props) {
  const {
    className = '', rootElProps = {}, useConfig, 
  } = props;
  const cookieValue = useSelector(state => state.cookies.cookieValue);
  const cookieProps = useCookieProps(useConfig);
  const dispatch = useDispatch();
  const onSubmit = useCallback(() => {
    if (import.meta.env.VITE_NAZKA_ENV === 'production') {
      createCookieServiceLog();
    }
  }, []);
  const onConsented = useCallback((newCookieValue) => {
    dispatch(setCookieValue(newCookieValue));
  }, [dispatch]);

  const { isLoading } = useTranslation();

  if (isLoading) {
    return null;
  }

  return (
    <CookieConsent
      {...cookieProps}
      rootElProps={rootElProps}
      className={className}
      cookieValue={cookieValue}
      onSubmit={onSubmit}
      onConsented={onConsented}
    />
  )
}

export default Cookies;