import { useTranslation } from '@utils/i18n/i18n';

import Sidepanel from '@App/components/Sidepanel/Sidepanel';
import DownloadForm from './DownloadForm/DownloadForm';
import DownloadMap from './DownloadMap/DownloadMap';

import './download.styl';

function Download() {
  const { t } = useTranslation();

  return (
    <div className="download">
      <Sidepanel 
        className="download__sidepanel"
        title={t('download')}
        icon="img/pages/download-icon.svg"
        intro={t('download_intro_text')}
      >
        <DownloadForm />
      </Sidepanel>
      <DownloadMap />
    </div>
  )
}

export default Download;