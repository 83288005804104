import { v4 as uuidv4 } from 'uuid';
import { bbox } from '@turf/turf';
import { MEDIA_BREAKPOINT_ENTRIES } from '@utils/AppWindow/app-window.constants';

function generateUniqueId() {
  return uuidv4();
}

function isMobile(currentBreakpoint, mobileMaxMediaBreakpoint) {
  const mediaBreakpoints = MEDIA_BREAKPOINT_ENTRIES.map(curr => curr[1]);
  const indexOfCurrent = mediaBreakpoints.indexOf(currentBreakpoint);
  const indexOfMobileMax = mediaBreakpoints.indexOf(mobileMaxMediaBreakpoint);
  
  return (indexOfCurrent <= indexOfMobileMax);
}

function removeExternalLoader() {
  const loaderEl = document.querySelector('.js-external-loader');

  if (!loaderEl) {
    return;
  }

  loaderEl.parentNode.removeChild(loaderEl);
}

function getSizeFromResizeObserverEntry(entry) {
  if (!entry.contentBoxSize) {
    return {
      width: entry.contentRect.width,
      height: entry.contentRect.height,
    };
  }

  const { 
    inlineSize: width, 
    blockSize: height, 
  } = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;

  return { width, height };
}

const tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*';

const tagOrComment = new RegExp(
  `${'<(?:'
  + '!--(?:(?:-*[^->])*--+|-?)' // Comment body.
  + '|script\\b'}${tagBody}(/|>[\\s\\S]*?</script\\s*)` // <script...>...</script> & <script.../>
  + `|style\\b${tagBody}(/|>[\\s\\S]*?</style\\s*)` // <style...>...<style> & <style.../>
  + `|iframe\\b${tagBody}(/|>[\\s\\S]*?</iframe\\s*)` // <iframe...>...<iframe> & <iframe.../>
  + ')>',
  'gi',
);

const sanitizeHTML = html => html.replace(tagOrComment, '');

function getDateGroups(features, cloudCoveragePercentage) {
  const defaultValue = { highlightedDates: [], cloudyDates: [], availableDates: [] };

  if (!features) {
    return defaultValue;
  }

  const sortedDates = features.reduce((acc, curr) => {
    if (curr?.properties?.productInformation?.cloudCover <= cloudCoveragePercentage) {
      acc.highlightedDates.push(new Date(curr?.properties?.date));
    } else {
      acc.cloudyDates.push(new Date(curr?.properties?.date));
    }

    acc.availableDates.push(new Date(curr?.properties?.date));

    return acc;
  }, defaultValue);

  return sortedDates;
}

function isSameDay(date1, date2) {
  return date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
}

function shouldDisableFormDate(date, selectedDates, allDays) {
  if (selectedDates.find(selectedDate => isSameDay(selectedDate, date))) {
    return true;
  }

  return !allDays.find(availableDate => isSameDay(availableDate, date));
}

function getBounds(feature) {
  const tilebbox = bbox(feature);
  return [[tilebbox[1], tilebbox[0]], [tilebbox[3], tilebbox[2]]];
}

function formatDateToYYYYMMDD(date) {
  const offset = date.getTimezoneOffset();
  const formatted = new Date(date.getTime() - (offset * 60 * 1000));
  return formatted.toISOString().split('T')[0];
}

function createStationOption(station) {
  return {
    id: station.properties.stationId,
    label: `${station.properties.site} - ${station.properties.stationType} (${station.properties.stationId})`,
  };
}

export {
  createStationOption,
  formatDateToYYYYMMDD,
  generateUniqueId,
  getBounds,
  getDateGroups,
  getSizeFromResizeObserverEntry,
  isMobile,
  removeExternalLoader,
  sanitizeHTML,
  shouldDisableFormDate,
}