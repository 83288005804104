import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import L from 'leaflet';

import TranslationsProvider from '@utils/i18n/i18n';
import translator from '@App/app.translator';
import StationMarkerPopup from '@App/components/StationMarkers/StationMarkerPopup/StationMarkerPopup';

import { useStations } from '@services/query.service';

const measurementIcon = L.icon({
  iconUrl: 'img/icons/measurement-marker.svg',
  iconSize: [19, 25],
  iconAnchor: [9.5, 13],
  popupAnchor: [1, 14.4],
});

const selectedMeasurementIcon = L.icon({
  iconUrl: 'img/icons/measurement-marker-selected.svg',
  iconSize: [19, 25],
  iconAnchor: [9.5, 13],
  popupAnchor: [1, 14.4],
});

function StationMarkers({ map, onMarkerClick, selectedStation }) {
  const { data: stations } = useStations();

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (!map || !stations) return;
    
    setMarkers(stations.map((station) => {
      const coordinates = [station?.geometry?.coordinates[1], station?.geometry?.coordinates[0]];

      const marker = L.marker(
        coordinates, 
        { icon: measurementIcon },
      );

      const popupDiv = document.createElement('div');
      const root = createRoot(popupDiv);

      root.render(
        <TranslationsProvider translator={translator}>
          <StationMarkerPopup station={station} />
        </TranslationsProvider>,
      );

      if (!onMarkerClick) {
        marker.bindPopup(popupDiv, { className: 'custom-popup' });
      }

      marker.on('click', () => {
        if (onMarkerClick) {
          onMarkerClick(station);
          map.setView(coordinates, 12);
        }
      });

      marker.addTo(map);

      return {
        station,
        marker,
      };
    }));
  }, [map, stations, onMarkerClick]);

  useEffect(() => {
    if (map && selectedStation) {
      const markerForStationId = markers.find(({ station }) => station.properties.stationId === selectedStation.id);
  
      if (markerForStationId) {
        markerForStationId.marker.setIcon(selectedMeasurementIcon);
        map.setView(markerForStationId.marker.getLatLng(), 14);
      }
    }

    return () => {
      markers.forEach((marker) => {
        marker.marker.setIcon(measurementIcon);
      });
    };
  }, [selectedStation, markers, map]);

  return null;
}

export default StationMarkers;