/* eslint-disable new-cap */
/* eslint-disable func-names */
// import * as GeoTIFF from 'geotiff';
import * as plotty from '@utils/Plotty/plotty';

function tempFixForTileGapBug() {
  // https://github.com/Leaflet/Leaflet/issues/3575
  const originalInitTile = L.GridLayer.prototype._initTile
  L.GridLayer.include({
    _initTile(tile) {
      originalInitTile.call(this, tile);

      const tileSize = this.getTileSize();

      // eslint-disable-next-line no-param-reassign
      tile.style.width = `${tileSize.x + 1}px`;
      // eslint-disable-next-line no-param-reassign
      tile.style.height = `${tileSize.y + 1}px`;
    },
  });
}

function addCanvasTileLayerExtension() {
  L.TileLayer.Canvas = L.TileLayer.extend({
    // Creates canvas tiles that unlike img tiles allow to get the colour 
    // of a specific point (see getImageData()), also performs much better
    createTile(coords, done) {
      let err;
      
      const tile = document.createElement('canvas');
      tile.setAttribute('class', 'canvas-tile');
      const ctx = tile.getContext('2d', { willReadFrequently: true });

      const { x: width, y: height } = this.getTileSize();
      tile.width = width * 2;
      tile.height = height * 2;

      const img = new Image();
      img.onload = () => {
        try {
          ctx.drawImage(img, 0, 0);
          tile.complete = true;
        } catch (e) {
          err = e;
        } finally {
          done(err, tile);
        }
      };
      const tileZoom = this._getZoomForUrl();
      img.src = Number.isNaN(tileZoom) ? '' : this.getTileUrl(coords);
      img.crossOrigin = 'Anonymous';
      return tile;
    },
  });

  L.tileLayer.canvas = function tileLayerCanvas(url, options) {
    return new L.TileLayer.Canvas(url, options);
  };
}

function addTiffTileLayerExtension() {
  L.TileLayer.TiffTile = L.TileLayer.extend({
    options: {
      crossOrigin: 'anonymous',
    },
    createTile(coords) {
      const tile = document.createElement('canvas');
      if (!this.options.disableScaling) {
        tile.setAttribute('class', 'tiff-tile');
      }
      
      const tileSize = this.getTileSize();
      tile.setAttribute('width', tileSize.x);
      tile.setAttribute('height', tileSize.y);
      const renderTile = async () => {
        tile.getContext('2d'); 
        const response = await fetch(this.getTileUrl(coords));
        const arrayBuffer = await response.arrayBuffer();
        const tiff = await GeoTIFF.fromArrayBuffer(arrayBuffer);
        const image = await tiff.getImage();
        const data = await image.readRasters();        
        try {
          const plot = new plotty.plot({
            canvas: tile,
            data: data[0],
            width: image.getWidth(),
            height: image.getHeight(),
            noDataValue: this.options.noData ?? 0,
            domain: [0, 4000],
            colorScale: this.options.colorScale,
          });
          
          plot.render();
          tile.coord = coords;
          tile.plot = plot;
        } catch (e) {
          console.log(e);
        }
      }
      renderTile();
      return tile;
    },
  });
  L.tileLayer.tiffTile = function (url, options) {
    return new L.TileLayer.TiffTile(url, options);
  };
}

export { tempFixForTileGapBug, addCanvasTileLayerExtension, addTiffTileLayerExtension };