import React, { useMemo } from 'react';
import { useTranslation } from '@utils/i18n/i18n';
import { Select, Button } from '@nazka/nazka.mapframe.components';

import theme from '@css/theme.style.json';

import './time-series-info-box.styl';

function CustomOption(option) {
  const {
    highlighted, selected, label, data, 
  } = option;

  return (
    <Button
      className="time-series-info-box__select__option"
      color={highlighted ? theme?.color?.common?.grey?.xxxxlight : 'white'}
      css={{ 
        fontWeight: selected ? 'bolder' : '',
        '> span:before': {
          backgroundColor: data?.color,
        },
      }}
    >
      {label}
    </Button>
  );
}

function TimeSeriesInfoBox({
  measurementSelection, activeMeasurement, setActiveMeasurement,
}) {
  const { t } = useTranslation();
  const measurement = useMemo(() => {
    return measurementSelection.find(ms => ms.typeCode === activeMeasurement);
  }, [activeMeasurement, measurementSelection]);

  return (
    <div className="time-series-info-box">
      <Select
        className="time-series-info-box__select"
        toggleButtonProps={{
          css: { 
            '> span': {
              paddingLeft: activeMeasurement ? '20px' : '',
            },
            '> span:before': {
              backgroundColor: measurement?.color,
            },
          }, 
        }}
        customMenuItems={CustomOption}
        value={measurement ? { id: measurement.typeCode, label: measurement.label } : null}
        onInputChange={(o) => {
          if (o) {
            setActiveMeasurement(o.id)
          }
        }}
        options={measurementSelection.map(ms => ({ 
          id: ms.typeCode, 
          label: ms.label, 
          data: { color: ms?.color }, 
        }))}
        measurementSelection={measurementSelection}
        placeholder={t('dropdown_placeholder')}
      />
    </div>
  )
}

export default TimeSeriesInfoBox;
