import { useSelector } from 'react-redux';

import PopupWindow from '@App/components/PopupWindow/PopupWindow';
import { useDownloadItems } from '@utils/QueryClient/download.queries';

import './download-confirmation.styl';

function DownloadConfirmation({
  closeAction,
  selectedTilesWithDates,
}) {
  const measurementTypes = useSelector(state => state.download.measurementTypes);

  const { isLoading, data, isError } = useDownloadItems(
    selectedTilesWithDates,
    measurementTypes.map(mt => mt.value),
  );

  return (
    <PopupWindow closeAction={closeAction} title="Your order" className="popup-window-download">
      <div className="download-confirmation">
        {isError && (
          <div>
            Something went wrong
          </div>
        )}
        {isLoading && (
          <div>
            Loading...
          </div>
        )}
        {!isLoading && !isError && measurementTypes.map((measurementType) => {
          return (
            <div>
              <h3>
                {measurementType.label}
              </h3>
              {selectedTilesWithDates.map((tile) => {
                return (
                  <div>
                    <h4>
                      {tile.properties.tileid}
                    </h4>
                    <div>
                      {tile.properties.dates.map((date) => {
                        const feature = data?.find(item => item.tileid === tile.properties.tileid
                          && item.date === date.split('T')[0]
                          && item.collection === measurementType.value);
                          
                        if (!feature || !feature?.properties?.links) {
                          return null;
                        }

                        const { links } = feature.properties;
                        
                        const flattenedLinks = Object.keys(links).flatMap((linkType) => {
                          return links[linkType].filter(link => link.title !== 'WMS').map(link => ({ ...link, linkType }));
                        });

                        return (
                          <div>
                            <div>
                              {date.split('T')[0]}
                            </div>
                            <ul className="download-confirmation__list">
                              {flattenedLinks.map((link) => {
                                return (
                                  <li>
                                    <a
                                      href={`/api/download?link=${encodeURIComponent(link.href)}`}
                                      download
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {link.title || link.href.split('/').pop().split('.')[0]}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </PopupWindow>
  );
}

export default DownloadConfirmation;