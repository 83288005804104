import React, { useState, useEffect, useRef } from 'react';
import { Input } from '@nazka/nazka.mapframe.components';

import './measurement-slider.styl';

function MeasurementSlider({
  max,
  minValue,
  maxValue,
  onChange,
  className,
  measurement,
}) {
  const [minInputValue, setMinInputValue] = useState(minValue);
  const [minInputIsFocused, setMinInputIsFocused] = useState(false);
  const [maxInputValue, setMaxInputValue] = useState(maxValue);
  const [maxInputIsFocused, setMaxInputIsFocused] = useState(false);
  const input = useRef();
  
  useEffect(() => {
    if (maxInputIsFocused) input.current.select();
  }, [maxInputIsFocused]);

  useEffect(() => {
    setMaxInputValue(maxValue);
  }, [maxValue]);

  return (
    <div className={`measurement-slider${className ? ` ${className}` : ''}`}>
      <div className="measurement-slider__extra">
        <Input
          rootElProps={{ ref: input }}
          className="measurement-slider__extra__input"
          value={minInputIsFocused ? minInputValue : `min ${minInputValue}${measurement.unit ? ` ${measurement.unit}` : ''}`}
          size={7}
          onInputChange={(e) => {
            if (/^\d+$/.test(e.target.value) || e.target.value === '') {
              setMinInputValue(e.target.value === '' ? e.target.value : Number(e.target.value));
              onChange(e.target.value !== '' ? [Number(e.target.value), maxInputValue] : [0, maxInputValue]);
            }
          }}
          onSelect={() => {
            setMinInputIsFocused(true);
          }}
          onBlur={(e) => {
            setMinInputIsFocused(false);
            if (e.target.value === '') {
              setMinInputValue(0);
              onChange([0, maxInputValue]);
            }
            if ((/^\d+$/.test(e.target.value))) {
              if (Number(e.target.value) > maxInputValue) {
                setMinInputValue(maxInputValue);
                onChange([maxInputValue, maxInputValue]);
              } else if (Number(e.target.value) > max) {
                setMinInputValue(max);
                onChange([max, maxInputValue]);
              }
            }
          }}
          type="text"
        />
        <Input
          rootElProps={{ ref: input }}
          className="measurement-slider__extra__input"
          value={maxInputIsFocused ? maxInputValue : `max ${maxInputValue}${measurement.unit ? ` ${measurement.unit}` : ''}`}
          size={7}
          onInputChange={(e) => {
            if (/^\d+$/.test(e.target.value) || e.target.value === '') {
              setMaxInputValue(e.target.value === '' ? e.target.value : Number(e.target.value));
              onChange(e.target.value !== '' ? [minInputValue, Number(e.target.value)] : [minInputValue, 0]);
            }
          }}
          onSelect={() => {
            setMaxInputIsFocused(true);
          }}
          onBlur={(e) => {
            setMaxInputIsFocused(false);
            if (e.target.value === '') {
              setMaxInputValue(0);
              onChange([minInputValue, 0]);
            }
            if ((/^\d+$/.test(e.target.value) && Number(e.target.value) > max)) {
              setMaxInputValue(max);
              onChange([minInputValue, max]);
            }
          }}
          type="text"
        />
      </div>
    </div>
  );
}

export default MeasurementSlider;